import { Flex } from '@rebass/grid'
import { get, isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button, LoaderWrapper, MioSelect } from '../../components'
import { getAddedDomains, getCompanyList } from '../../services/sengrid-settings'
import SendgridModal from './SendgridModal/SendgridModal'


const SendGridContainer = () => {

    const [companyList, setCompanyList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [message, setMessage] = useState('');
    const [selectedCompanyId, setSelectedCompanyId] = useState('');
    const [domainList, setDomainList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cnames, setCnames] = useState({});
    const [domainName, setDomainName] = useState('');

    useEffect(() => {
        getCompanyList()
            .then(res => {
                setCompanyList(get(res, 'result.data', []));
                setMessage('')
            })
            .catch(err => {
                setMessage('Error while fetching company list');
            });
    }, []);

    console.log(selectedCompanyId);

    const addDomainClick = () => {
        if(!isEmpty(selectedCompanyId)) {
            setOpenModal(true);
        } else {
            setMessage('Please Select a Company');
        }
    }

    const searchDomain = () => {
        if(!isEmpty(selectedCompanyId)) {
            setLoading(true);
            getAddedDomains(selectedCompanyId).then(res => {
                setLoading(false);
                setDomainList(get(res, 'result.data', []));
                setMessage('');
            })
            .catch(err => {
                setLoading(false);
                setMessage('Error while fetching domains');
            });
        } else {
            setMessage('Please Select a Company');
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
        setMessage('');
    }

    const handleVerify = ({ domain, cnames }) => {
        setCnames(JSON.parse(cnames));
        setDomainName(domain)
        setOpenModal(true);
    }

    return (
        <Flex width="100%" mt="20px" flexDirection="column">
            <Flex justifyContent="center" fontSize="16px" style={{ fontFamily: 'Poppins' }}>
                SENDGRID SETTINGS
            </Flex>

            <Flex width="100%" fontSize="16px" mt="20px" style={{ fontFamily: 'Poppins' }}>
                <Flex alignItems="center" width="20%">
                    Search By Company
                </Flex>
                <Flex alignItems="center" width="60%">
                    <MioSelect width="40%" onChange={(e) => setSelectedCompanyId(e.target.value)}>
                        <option value="">Please Select A Company</option>
                        {!isEmpty(companyList) && companyList.map(company => (
                            <option key={company.cid} value={company.cid}>{company.name}</option>
                        ))}
                    </MioSelect>
                    <Button onClick={searchDomain} margin="0px 20px">Get Domains</Button>
                </Flex>
                <Flex width="20%">
                    <Button onClick={addDomainClick} margin="0px">Add Domain</Button>
                </Flex>
            </Flex>

            {message && (
                <Flex width="100%" justifyContent="center">
                    {message}
                </Flex>
            )}


            <Flex fontSize="16px" mt="20px" style={{ fontFamily: 'Poppins' }} justifyContent="center">
                ADDED DOMAINS
            </Flex>

            <Flex padding="10px" mt="10px" backgroundColor="#fff" width="100%">
                <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">Domain</Flex>
                <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">Status</Flex>
                <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">Verification Status</Flex>
                <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="#ccc" justifyContent="center"></Flex> 
            </Flex>

            <LoaderWrapper isLoading={loading}>
                {!isEmpty(domainList) && domainList.map((item) => (
                    <Flex alignItems="center" padding="10px" backgroundColor="#fff" width="100%">
                        <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">{item.domain}</Flex>
                        <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">{item.status}</Flex>
                        <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="black" justifyContent="center">{item.verification_status}</Flex>
                        {item.verification_status === "verification_pending" && (
                            <Flex fontSize="15px" style={{ fontFamily: 'Poppins' }} width="25%" color="#ccc" justifyContent="center">
                                <Button onClick={() => handleVerify(item)} margin="0px">
                                    Verify
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                ))}
            </LoaderWrapper>    
           

            {openModal && (
                <SendgridModal
                    isOpen={openModal}
                    handleClose={handleCloseModal}
                    selectedCompanyId={selectedCompanyId}
                    cnames={cnames}
                    domainName={domainName}
                />
            )}
        </Flex>
  )
}

export default SendGridContainer