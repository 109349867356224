import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from '@rebass/grid';

import { HeaderDiv } from './Header.styles';
import { HeaderLogo } from './Header.styles';
import { logo } from '../../utils/constants';

const Header = ({ children, logoUrl }) => (
  <HeaderDiv screenWidth={window.innerWidth}>
    <Flex
      width={1}
      justifyContent="space-between"
      alignItems="center"
      height="70px"
      pl='50px'
      pr='50px'
    >
      <Box height="100%">
        <Link to={logoUrl}>
          <HeaderLogo
            src={logo['01']}
            alt="mio-logo"
          />
        </Link>
      </Box>
      <Box height="100%">{children}</Box>
    </Flex>
  </HeaderDiv>
);

export default Header;
