/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalCloseButton } from '../../../components';
import StepStatus from './StepStatus/StepStatus';
import MainContentComponent from './MainContentComponent/MainContentComponent';
import { postAddDomain, postValidateDomain } from '../../../services/sengrid-settings';
import { isEmpty } from 'lodash';

const SendgridModal = ({ adminModal, isOpen, handleClose, selectedCompanyId, cnames, domainName }) => {
    const [stepCompleted, updateStepCompleted] = useState(1);
    const [loginDetails, setLoginDetails] = useState({});
    const [cNamesList, setCNamesList] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [nextLoader, toggleNextLoader] = useState(false);
    const [verifyLoader, toggleVerifyLoader] = useState(false);
    const [deleteLoader, toggleDeleteLoader] = useState(false);
    const [verificationStatus, updateVerificationStatus] = useState(null);
    const [showDelete, toggleDelete] = useState(false);

    useEffect(() => {
        if(!isEmpty(cnames) && domainName) {
            setCNamesList(getCNamesList(cnames));
            setLoginDetails({
                domain_name: domainName
            })
            updateStepCompleted(2);
        }
    }, [])
    


    const getCNamesList = cnamesObj => {
        const cnamesList = [{}, {}, {}]
        Object.keys(cnamesObj).map(cname => {
            if (cname.includes('1'))
               return cnamesList[0][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
            else if (cname.includes('2'))
               return cnamesList[1][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
            else
               return cnamesList[2][cname.slice(0, cname.length - 1)] = cnamesObj[cname];
        });
        return cnamesList;
    }

    const inputChangeHandler = source => {
        const loginDetailsCopy = { ...loginDetails };
        loginDetailsCopy[source.target.name] = source.target.value;
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (pattern.test(loginDetailsCopy.email) && loginDetailsCopy.domain_name.length > 0)
            loginDetailsCopy.valid = true;
        else
            loginDetailsCopy.valid = false;
        setLoginDetails(loginDetailsCopy);
    }

    const loginHandler = () => {
            setErrorMessage('');
            toggleNextLoader(true);
            console.log(loginDetails);
            const form = {
                snetwork: 'sg',
                domain: loginDetails.domain_name,
                company_id: selectedCompanyId,
                automatic_security: true
            };
            postAddDomain(form)
                .then(response => {
                    console.log('postAddDomain [RESPONSE] - ', response);
                    if(response.result.success === 'true') {
                        toggleNextLoader(false);
                        setCNamesList(getCNamesList(response.result.data));
                        updateStepCompleted(2);
                      
                    }
                })
                .catch(err => {
                    toggleNextLoader(false);
                    setErrorMessage('There was some error logging in..please try again later..');
                });
    }

    const verifyHandler = () => {
        setErrorMessage('');
        toggleVerifyLoader(true);
        const form = {
            snetwork: 'sg',
            company_id: selectedCompanyId,
            domain: loginDetails.domain_name,
            email: ''
        };
        postValidateDomain(form)
            .then(response => {
                console.log('[RESPONSE] - ', response);
                toggleVerifyLoader(false);
                if (response.result.success === 'true'){
                    updateStepCompleted(3);
                }
                else
                    setErrorMessage('Verification Failed, please check if the entries are properly added to your DNS.');
            })
            .catch(err => {
                console.log('[ERROR] - ', err);
                toggleVerifyLoader(false);
                setErrorMessage('Verification Failed, please check if the entries are properly added to your DNS.');
            });
        // updateStepCompleted(3);
    }
    
    const closeHandler = () => {
        handleClose();
    }

    // const updateConnectionHandler = option => {
    //     setErrorMessage('');
    //     if(option === 'disconnect')
    //         toggleDisconnectLoader(true);
    //     else if(option === 'delete')
    //         toggleDeleteLoader(true);
    //     else
    //         toggleConnectLoader(true)
    //     const form = {
    //         snetwork: 'sg',
    //         email: loginDetails.email,
    //         domain: loginDetails.domain_name,
    //         status: option
    //     }
    //     updateConnectionStatus(form)
    //     .then(response => {
    //         console.log('[DISCONNECT] response - ', response);
    //         if(option === 'disconnect') {
    //             toggleDisconnectLoader(false);
    //             toggleDisconnect(true);
    //             // updateStatus('disconnected');
    //         }
    //         else if(option === 'delete') {
    //             toggleDeleteLoader(false);
    //             toggleDelete(false);
    //             closeHandler();
    //         }
    //         else {
    //             toggleConnectLoader(false);
    //             closeHandler();
    //         }
    //     })
    //     .catch(err => {
    //         console.log('[ERROR] disconnect connection - ', err);
    //         if(option === 'disconnect')
    //             toggleDisconnectLoader(false);
    //         else if(option === 'delete')
    //             toggleDeleteLoader(false);
    //         else
    //             toggleConnectLoader(false);
    //         setErrorMessage('Error processing request...');
    //     });
    //     // toggleDisconnectLoader(false);
    //     // toggleDisconnect(true);
    // }

    return (
        <Modal isOpen={isOpen} onRequestClose={handleClose} width={!verificationStatus || verificationStatus === 'verification_pending' ? (stepCompleted === 2 ? '58%' : '45%') : '30%'}>
            <ModalHeader>
                <ModalCloseButton onClick={handleClose} />
            </ModalHeader>
            <ModalBody>
                <div style={{ textAlign: 'center', margin: 'auto', backgroundColor: '#6351ed', borderRadius: '26px', width: '51px', padding: '7px 10px 6px 10px' }}>
                    <img alt="" src='/assets/images/SendGrid.svg' />
                </div>
                <div>
                    <h3 style={stepCompleted === 3 || verificationStatus === 'verified' ? { marginBottom: '0' } : {}}>
                        {(!verificationStatus || verificationStatus === 'verification_pending') ? (
                            stepCompleted === 1 ? 'Connect with SendGrid' : stepCompleted === 2 ? `Add these records to ${loginDetails.domain_name} DNS section` : showDelete ? 'Are you sure you want to delete this connection' : 'Verification Successful!'
                         ) : !showDelete ? 'Connection Disconnected' : showDelete ? 'Are you sure you want to delete this connection' : 'Would you like to'}
                    </h3>
                    {(!verificationStatus || verificationStatus === 'verification_pending') ? (
                        stepCompleted === 3 ? showDelete ? <p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>To connect back you have to add and verify the SendGrid connections again</p> : (<p style={{ color: '#49484a', fontSize: '10px', marginTop: '5px' }}>You can start sending mails to your SendGrid Connections</p>) : null
                    ) : null}
                </div>
                {(verificationStatus === 'verification_pending') ? (
                    <StepStatus stepCompleted={stepCompleted} />
                ) : null}
                <MainContentComponent
                    stepCompleted={stepCompleted}
                    loginDetails={loginDetails}
                    changeHandler={inputChangeHandler}
                    loginHandler={loginHandler}
                    verifyHandler={verifyHandler}
                    isAdmin={adminModal}
                    list={cNamesList}
                    verifyLoader={verifyLoader}
                    nextLoader={nextLoader}
                    close={closeHandler}
                    verificationStatus={verificationStatus}
                    showDelete={showDelete}
                    toggleDelete={toggleDelete}
                    deleteLoader={deleteLoader}
                    setErrorMessage={setErrorMessage}
                    updateVerificationStatus={updateVerificationStatus}
                    toggleDeleteLoader={toggleDeleteLoader} />

                {errorMessage ? (
                    <div style={{ margin: 'auto', textAlign: 'center', fontSize: '10px' }}>
                        <p>*{errorMessage}</p>
                    </div>
                ) : null}
            </ModalBody>
        </Modal>
    );
}


export default SendgridModal;