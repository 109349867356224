import React from 'react';

import { StyledInput, StyledInputLarge } from '../';

const Input = ({
  type,
  name,
  id,
  value,
  placeholder,
  error,
  onChange,
  largeInput,
  disabled,
  onKeyDown,
  width,
  bgColor,
  marginBottom,
  containerWidth,
  required,
  autoComplete,
  maxLength
}) => (
  <div style={{width: containerWidth || '' }}>
    {largeInput ? (
      <StyledInputLarge
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        width={width}
        bgColor={bgColor}
        marginBottom={marginBottom}
      />
    ) : (
      <StyledInput
        type={type}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        onKeyDown={onKeyDown}
        required={required}
        autoComplete={autoComplete}
        maxLength={maxLength}
      />
    )}

    {error && <div className="validation-error">{error}</div>}
  </div>
);

export default Input;
