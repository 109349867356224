import React from 'react';
import { Input, Button, LoaderWrapper } from '../../../../../components'
import './StepOneComponent.css';

const stepOneComponent = ({ loginDetails, changeHandler, loginHandler, isAdmin, verificationStatus, sgData, nextLoader }) => {
    return (
        <div style={{ margin: 'auto', width: '70%', textAlign: 'left' }}>
            <label style={{ color: '#49484a' }} htmlFor='domain_name'>Domain Name:</label>
            <Input
                id='domain_name'
                name='domain_name'
                largeInput={true}
                placeholder=''
                onChange={changeHandler}
                value={loginDetails.domain_name} />
            <div style={{ width: '100%', textAlign: 'center' }}>
                <LoaderWrapper isLoading={nextLoader}>
                    <Button className='connect-btn-sendgrid' margin='auto' onClick={loginHandler}>Next</Button>
                </LoaderWrapper>
            </div>
        </div>
    );
}

export default stepOneComponent;