import apiInstance from "../api";
import { objectToFormData } from "../helpers";

const formatDate = (date) => {
  let dd = date.getDate();
  let mm = date.getMonth() + 1;
  let yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }
  let newDate = yyyy + "/" + mm + "/" + dd;

  return newDate;
};

export const postUser = (payload) => {
  if (payload.dob) {
    payload.dob = formatDate(payload.dob);
  }

  const data = objectToFormData(payload);
  return apiInstance().post("/user", data);
};

export const getZipcodeInfo = (zipcode) => {
  return apiInstance().get(`/address/?zip=${zipcode}`);
};

export const getEnterpriseList = () => {
  return apiInstance().get('/enterprise/');
};

export const getFirmsLists = ({ id }) => {
  return apiInstance().get(`/enterprise/firm/?ent_id=${id}`);
}
