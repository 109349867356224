import { Flex } from '@rebass/grid';
import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
} from '../../components/Modal';

export const DeleteMyEditorContentModal = ({
  open,
  handleClose,
  deleteId,
  deleteMyEditorContentRequest,
}) => {

  const handleSubmit = () => {
    deleteMyEditorContentRequest({deleteId});
    handleClose();
  };

  return (
    <Modal
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick
      isOpen={open}
      size="smail"
      isDeleteMyEditorContent={true}
    >
      <ModalCloseButton onClick={handleClose} />
      <ModalBody>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#6351ed',
            fontFamily: 'Poppins',
            wordBreak: 'break-all',
          }}
        >
          Are you sure you want to delete ?
        </div>
          <Flex justifyContent='center'>
          <div
          style={{
            fontSize: '13px',
            padding: '5px 28px',
            fontFamily: 'Poppins',
            backgroundColor: '#fff',
            color: '#6351ed',
            border: '1px solid #fff',
            margin: '13px 10px 0px 0px',
            cursor: 'pointer',
            height: 'auto',
            width:'auto',
          }}
            onClick={handleSubmit}
          >
            Yes
          </div>
          <div
             style={{
            fontSize: '13px',
            padding: '5px 28px',
            fontFamily: 'Poppins',
            backgroundColor: '#fff',
            color: '#6351ed',
            border: '1px solid #fff',
            margin: '13px 10px 0px 0px',
            cursor: 'pointer',
            height: 'auto',
            width:'auto',
          }}
            onClick={handleClose}
          >
            No
          </div>
          </Flex>
      </ModalBody>
    </Modal>
  );
};
