/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { MioDropdown } from "../";
import Header from "./Header";
import { HeaderUl, HeaderLI, IconTitleContainer } from "./Header.styles";
import { clearSeassionStorage } from "../../utils";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserPlus, faUpload } from "@fortawesome/free-solid-svg-icons";

const AdminHeader = ({ pathName, userName, roleType }) => {
  const history = useHistory();

  return (
    <Header logoUrl="/dashboard">
      <nav>
        <HeaderUl>
          <HeaderLI selected={"/dashboard" === pathName}>
            <Link to="/dashboard">
              <IconTitleContainer>
                <i className="icon dripicons-home" />
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  HOME
                </span>
              </IconTitleContainer>
            </Link>
          </HeaderLI>
          <HeaderLI selected={"/create-user" === pathName}>
            <Link to="/create-user">
              <IconTitleContainer style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faUserPlus}
                  style={{ paddingBottom: "2px" }}
                />
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  ADD USER
                </span>
              </IconTitleContainer>
            </Link>
          </HeaderLI>
          <HeaderLI selected={"/add-content" === pathName}>
            <Link to="/my-editor-content">
              <IconTitleContainer style={{ alignItems: "center" }}>
                <FontAwesomeIcon
                  icon={faUpload}
                  style={{ paddingBottom: "2px" }}
                />
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  EDITOR CONTENT
                </span>
              </IconTitleContainer>
            </Link>
          </HeaderLI>
          <HeaderLI selected={"/notification" === pathName}>
            <Link to="/notification">
              <IconTitleContainer style={{ alignItems: "center" }}>
                <img alt="" src='/assets/images/bell_icon.svg' style={{width: '23px'}} />
                <span style={{ fontSize: "10px", fontWeight: "bold" }}>
                  DELAYED POSTS
                </span>
              </IconTitleContainer>
            </Link>
          </HeaderLI>
          <MioDropdown
            background="#e4e4e4"
            openOnHover
            right="18px"
            dropdownMenu={
              <ul>
                 <li>
                  <a
                    href="#"
                    style={{ fontSize: "15px" }}
                    onClick={() => history.push('/sendgrid-settings')}
                  >
                    Sendgrid Settings
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    style={{ fontSize: "15px" }}
                    onClick={clearSeassionStorage}
                  >
                    Logout
                  </a>
                </li>
              </ul>
            }
          >
            <HeaderLI selected={"/admin/configure" === pathName}>
              <a href="javascript:void(0);">
                <i className="icon icon dripicons-user" /> {userName}
              </a>
            </HeaderLI>
          </MioDropdown>
        </HeaderUl>
      </nav>
    </Header>
  );
};

export default AdminHeader;
