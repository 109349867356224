import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@rebass/grid';
import { LoaderFlex } from './Loader.styles';
import { ResponsiveImg } from '../';

const Loader = ({ height, imageUpload, styles }) => {
  return (
    <LoaderFlex
      width={1}
      alignItems="center"
      justifyContent="center"
      height={height || '100%'}
      style={styles ? styles : {}}
    >
      <Box>
        <ResponsiveImg src="/assets/images/loaderV.gif" alt="loader" />
        {imageUpload && imageUpload === 'true' && <div>We are customizing your content.</div>}
      </Box>
    </LoaderFlex>
  );
};

export const LoaderWrapper = ({ children, loaderHeight, isLoading, isDelay, imageUpload, styles }) => {
  const didMountRef = useRef(null);
  const [loaderPause, setLoaderPause] = useState(isLoading);
  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!isLoading) {
        //setTimeout(() => { //removed setTimeout as it causes loader and data visible at same time
        if(isDelay) {
          setTimeout(() => {
            setLoaderPause(false);
          }, 8000);
        } else {
          setLoaderPause(false);
        }
        //}, 1000);
      }
      if (isLoading) {
        setLoaderPause(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (loaderPause) {
    return <Loader height={loaderHeight} imageUpload={imageUpload} styles={styles} />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default Loader;
