import React, { useState, useRef, useEffect } from 'react';

import { MioDropdownWrapper, MioDropdownMenu } from './MioDropdown.styles';

const MioDropdown = ({
  children,
  openOnHover,
  fullWidth,
  type,
  background,
  dropdownMenu: DropdownMenu,
  hoverBackground,
  right
}) => {
  const menuWrapper = useRef(null);
  const menuListWrapper = useRef(null);
  const [menuOpenClass, setMenuOpenClass] = useState('');
  // const [menuListStyle, setMenuListStyle] = useState({});
  const targetEventsProps = {};

  const handleClickOutside = (e) => {
    if (menuWrapper.current && !menuWrapper.current.contains(e.target)) {
      setMenuOpenClass('');
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!openOnHover) {
    targetEventsProps.onClick = () => {
      const menuClass = menuOpenClass === '' ? 'open' : '';
      setMenuOpenClass(menuClass);
    };
  }

  const onMenuMouseOver = () => {
    if(type !== 'date') {
    /*setMenuListStyle({
      left: `${(menuParentWrapper - menuItemsWrapper) / 2}px`,
    });*/
    }
  };

  return (
    <MioDropdownWrapper
      ref={menuWrapper}
      fullWidth={fullWidth}
      openOnHover={openOnHover}
      onMouseOver={onMenuMouseOver}
      {...targetEventsProps}
    >
      {children}
      <MioDropdownMenu
        background={background}
        hoverBackground={hoverBackground}
        ref={menuListWrapper}
        className={`dropdown-menu ${menuOpenClass}`}
        style={{}}
        right={right}
      >
        {DropdownMenu}
      </MioDropdownMenu>
    </MioDropdownWrapper>
  );
};

export default MioDropdown;
