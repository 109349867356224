import { get, isEmpty } from 'lodash';
import { takeLatest, all, call, put } from 'redux-saga/effects';
import { actions as loginActions } from '../modules/login.module';

import {
  authenticate,
  getUserDetails
} from '../../services/login';

export function* loginRequest({ payload }) {
  try {
    const params = get(payload, 'params', {});
    const isRetrieveUser = isEmpty(params);
    const apiCall = isRetrieveUser
      ? getUserDetails
      : authenticate;
    const response = yield call(apiCall, get(payload, 'params', {}));
    const data = get(response, 'result.data', {});

    if (!isRetrieveUser) {
      const token = get(data, 'token', '');
      const advisorToken = get(data, 'secondary_account.data.token', '');

      sessionStorage.setItem('token', token);
      if (advisorToken) sessionStorage.setItem('advisorToken', advisorToken);
    }
    yield put(loginActions.api.login.response(data));
  } catch (error) {
    yield put(loginActions.api.login.error(error));
  }
}

function* loginSaga() {
  yield all([takeLatest(loginActions.api.login.request, loginRequest)]);
}

export default loginSaga;
