import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('myEditorContent');

const reducer = handleActions(
  {
    [actions.api.myEditorContent.request]: (_, { payload }) => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.myEditorContent.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.myEditorContent.delete]: (_, { payload }) => ({
      isFetching: true,
      isError: false,
      isDeleting: true
    }),
    [actions.api.myEditorContent.error]: (_, { payload }) => ({
        isFetching: false,
        isError: true,
        error: payload,
    })
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;