import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const getAllUsers = () => {
  return apiInstance().get('/user/all');
}

export const getUserToken = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post('/user/login/email/', data);
}