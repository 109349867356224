import apiInstance from "../api";
import { objectToFormData } from "../helpers";

export const getCompanyList = () => {
    return apiInstance().get(
        `/company/`
    );
};

export const getAddedDomains = (companyId) => {
    return apiInstance().get(`/social/account/emaildeliveryservice/domains?company_id=${companyId}`);
}

export const postAddDomain = (payload) => {
    const data = objectToFormData(payload);
    return apiInstance().post(
      `social/account/emaildeliveryservice/add_domain`,
      data
    );
  };
  
  export const postValidateDomain = (payload) => {
    const data = objectToFormData(payload);
    return apiInstance().post(
      `social/account/emaildeliveryservice/validate_domain`,
      data
    );
  };
  
  export const updateConnectionStatus = (payload) => {
    const data = objectToFormData(payload);
    return apiInstance().post(`social/account/emaildeliveryservice/status`, data);
  };