export const USER_ROLE = {
  admin: 'admin',
  general: 'general',
};

export const ROLE_TYPES = {
  ADVISOR_ENTERPRISE: 'ADVISOR_ENTERPRISE',
  ADVISOR_PREMIUM: 'ADVISOR_PREMIUM',
  ADVISOR_FREE: 'ADVISOR_FREE',
  ADVISOR_ASSET_MANAGER: 'ADVISOR_ASSET_MANAGER',
  ADMIN_ENTERPRISE: 'ADMIN_ENTERPRISE',
  ADMIN_COMPLIANCE: 'ADMIN_COMPLIANCE',
  ADMIN_ASSET_MANAGER: 'ADMIN_ASSET_MANAGER',
};

export const logo = {
  '01':'/assets/images/clout-logos/screen/clout-logos-01.png',
  '02':'/assets/images/clout-logos/screen/clout-logos-02.png',
  '03':'/assets/images/clout-logos/screen/clout-logos-03.png',
  '04':'/assets/images/clout-logos/screen/clout-logos-04.png',
  '05':'/assets/images/clout-logos/screen/clout-logos-05.png',
  '06':'/assets/images/clout-logos/screen/clout-logos-06.png',
  '07':'/assets/images/clout-logos/screen/clout-logos-07.png',
  '08':'/assets/images/clout-logos/screen/clout-logos-08.png',
  '09':'/assets/images/clout-logos/screen/clout-logos-09.png',
  '10':'/assets/images/clout-logos/screen/clout-logos-10.png',
  '11':'/assets/images/clout-logos/screen/clout-logos-11.png',
  '12':'/assets/images/clout-logos/screen/clout-logos-12.png',
}

export const themesData = {
  white: '#ffffff',
  purpleishBlue: '#6351ed',
  charcoalGrey: '#49484a',
  greyWhite: '#ececec',
  lightGrey: '#eeeeee',
  article:{
    background: '#ececec'
  },
  purpleyGrey: '#969198',
  teal: '#7dc5d0', 
  black: '#000000'
}