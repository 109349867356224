import React from "react";

import { StyledUploadFileButton } from "../CommonStyles";

export const UploadFileButton = ({
  type,
  upperCase,
  onClick,
  children,
  disabled,
  className,
  height,
  width,
  margin,
  padding,
  isSecondary,
  color,
  bgColor,
  styles,
  id,
  forLabel,
}) => (
  <StyledUploadFileButton
    id={id}
    for={forLabel}
    type={type || "button"}
    upperCase={upperCase}
    onClick={onClick}
    disabled={disabled}
    className={className}
    height={height}
    width={width}
    margin={margin}
    padding={padding}
    isSecondary={isSecondary}
    color={color}
    bgColor={bgColor}
    style={styles ? styles : {}}
  >
    {children}
  </StyledUploadFileButton>
);
