import styled from 'styled-components';

export const MioDropdownWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  .dropdown-menu {
    display: none;
    &.open {
      display: block;
    }
  }
  ${({ openOnHover }) =>
    openOnHover &&
    `:hover {
        .dropdown-menu {
            display: block;
        }
    }
  `}
`;

export const MioDropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  z-index: 100;
  right: ${({ right }) =>
    right || '0px'};
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    box-shadow: 0px 1px 2px #ddd;
    -webkit-box-shadow: 0px 1px 2px #ddd;
    max-height: ${(props) => (props.type === 'emailList' ? '280px' : '')};
    overflow-y: ${(props) => (props.type === 'emailList' ? 'auto' : '')};
    li {
      -webkit-box-sizing: border-box; /* Safari, other WebKit */
      -moz-box-sizing: border-box;    /* Firefox, other Gecko */
      box-sizing: border-box;         /* Opera/IE 8+ */
      background: ${({ background }) => background || '#ffffff'};
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-top: none;
      padding: 4px 5px;
      cursor: pointer;
      text-align: ${(props) => (props.type === 'emailList' ? 'left' : '')};
      font-size: ${(props) => (props.type === 'emailList' ? '12px' : '')};
      transition: all 0.2s;
      width: ${(props) => (props.type === 'date' ? '13.2em' : props.type === 'analytics' ? '10em' : props.type === 'emailList' ? 'auto' : '8em')};
      min-width: ${({ hoverBackground }) => hoverBackground ? '19em' : ''};
      a {
        display: block;
        width: 100%;
        height: 100%;
        font-size: ${(props) => (props.type === 'analytics' ? '16px' : '12px')};
        padding: 0px;
        border: 0px;
        justify-content: center;
        text-decoration: none;
      }
      &:hover {
        background: ${({ hoverBackground }) => hoverBackground || '#6f53a7'};
        color: #fff;
        font-size: ${(props) => (props.type === 'emailList' ? '12px' : '')};
        a {
          color: #fff;
          font-size: ${(props) => (props.type === 'analytics' ? '16px' : props.type === 'emailList' ? '8px' : '12px')};
        }
      }
    }
  }
`;
