import axios from 'axios';
import { get, isEmpty } from 'lodash';
import { apiDetails } from './helpers';
import { redirectToLogin } from '../utils';

//pass asAdvisor as true during admin login to get acccess to advisor related APIs
function createAPIInstance(options) {
  // Set config defaults when creating the instance

  const isReactLogin = get(options, 'isReactLogin', false);
  let authToken = window.sessionStorage.getItem('token');
  if (!authToken && !isReactLogin) {
    // setting token from cookie
    redirectToLogin();
  }

  const advisorAuthToken = window.sessionStorage.getItem('advisorToken');
  const asAdvisor = get(options, 'asAdvisor', false);
  const extraHeader = get(options, 'extraHeader', {});

  const tokenHeader = isEmpty(authToken)
    ? {}
    : asAdvisor
    ? !!advisorAuthToken
      ? {
          Authorization: `Bearer ${advisorAuthToken}`,
          ...extraHeader,
        }
      : {
          Authorization: `Bearer ${authToken}`,
          ...extraHeader,
        }
    : {
        Authorization: `Bearer ${authToken}`,
        ...extraHeader,
      };
  const api = axios.create({
    baseURL: apiDetails.baseUrl.trim(),
    headers: {
      'Content-Type': 'multipart/form-data',
      ...tokenHeader,
    },
  });

  api.interceptors.response.use(
    (response) => {
      const errorResponse = get(response, 'data.result.error', {});
      if (!isEmpty(errorResponse)) {
        throw errorResponse;
      }
      return response.data;
    },
    (error) => {
      if (error && error.response && error.response.status === 401) {
        redirectToLogin();
        return null;
      }
      return Promise.reject(error);
    }
  );
  return api;
}

export default createAPIInstance;
