import styled from 'styled-components';
import { theme, ButtonNoBorderBackground } from '../../components';

export const DashboardWrapper = styled.div.attrs({
  className: 'dashboard-wrapper'
})`
  padding-top: 70px;
  width: 100%;
`;

export const StyledSearchInput = styled.input`
  padding-left: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  height: 42px;
  width: 350px;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #d0d0d0;
  box-shadow: none;
  text-align: center;
  padding-right: 45px;
  margin-left: 5px;
  margin-right: 5px;
  ::placeholder {
    color: ${theme.color.font.primary};
  }
  :focus::placeholder {
    color: transparent;
  }
`;

export const SearchButton = styled(ButtonNoBorderBackground)`
  position: absolute;
  right: 10px;
  top: 14px;
  color: ${theme.color.font.primary};
`;

export const ClearButton = styled(ButtonNoBorderBackground)`
  position: absolute;
  right: 30px;
  top: 14px;
  color: ${theme.color.font.primary};
`;