import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

const FlexFullWidth = styled(Flex)`
  width: 100%;
`;

export const Table = styled(FlexFullWidth).attrs({
  className: 'mio-table',
})`
  background-color: #d4d4d4;
  flex-direction: column;
  font-size: 18px;
`;

export const TableTitle = styled.div.attrs({
  className: 'mio-table-title',
})`
  width: 100%;
  padding: 10px 0;
`;

export const TableHeading = styled(FlexFullWidth).attrs({
  className: 'mio-table-head',
})`
  border-bottom: 1px solid #a185d9;
  text-align: center;
`;

export const TableBody = styled(FlexFullWidth).attrs({
  className: 'mio-table-body',
})`
  flex-direction: column;
  overflow-y: auto;
`;

export const Tr = styled(FlexFullWidth).attrs({
  className: 'mio-table-row',
})`
  ${({ alternate }) =>
    alternate === false
      ? ''
      : `&:nth-child(odd) {
        background-color: #dcdcdc;
        .table-cell {
          &:nth-child(odd) {
          }
          &:nth-child(even) {
            background-color: #d7d7d7;
          }
        }
      }
      
      &:nth-child(even) {
        background-color: #d7d7d7;
        .table-cell {
          &:nth-child(odd) {
          }
          &:nth-child(even) {
            background-color: #cccccc;
          }
        }
      }`}
`;

export const Th = styled(Box).attrs({
  className: 'mio-table-head-cell',
})`
  flex: 1 1 0px;
  padding: 20px 15px;
  ${({ align }) => align && `text-align:${align};`}
  ${({ width }) => width && `max-width:${width};`}
`;

export const Td = styled(Box).attrs({
  className: 'table-cell mio-table-body-cell',
})`
  padding: 15px;
  flex: 1 1 0px;
  ${({ flex }) => flex && `flex:${flex};`}
  ${({ align }) => align && `text-align:${align};`}
  ${({ width }) => width && `max-width:${width};`}
`;
