import React, { useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Grid } from '../components/CommonStyles';
import {
  getUserDetails,
  getUserRole,
  getRoleType,
} from '../redux/selector';
import { actions as loginActions } from '../redux/modules/login.module';
import { AdminHeader, Loader } from '../components';

const withDashboardLayout = (Component) => {
  const ComposedComponent = ({
    loginRequest,
    history,
    userName,
    userRole,
    isFetching,
    roleType,
    user,
  }) => {
    console.log('NOTIFICATION - - - -', get(history, 'location.pathname', '/dashboard') === '/notification');
    // const [menuToggle, setMenuToggle] = useState(false);
    useEffect(() => {
      if (!userName) {
        loginRequest();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const HeaderComponent = AdminHeader;

    // const handleMenuToggle = () => {
    //   setMenuToggle(!menuToggle);
    // }

    if (isFetching) {
      return <Loader height="125vh" />;
    }

  
    return (
      <div>
        <HeaderComponent
          userName={userName}
          pathName={history.location.pathname}
          roleType={roleType}
        />
        <Grid dashboard notification={get(history, 'location.pathname', '/dashboard') === '/notification'}>
          <Component roleType={roleType} />
        </Grid>
      </div>
    );
  };

  const mapStateToProps = (state) => {
    const { first: firstName } = get(getUserDetails(state), 'name', {});
    return {
      userName: firstName,
      userRole: getUserRole(state),
      isFetching:
        state.user.isFetching,
      roleType: getRoleType(state),
      user: state.user.data,
    };
  };
  const mapDispatchToProps = {
    loginRequest: loginActions.api.login.request
  };
  return connect(mapStateToProps, mapDispatchToProps)(ComposedComponent);
};

export default withDashboardLayout;
