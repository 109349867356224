/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useCallback } from 'react';
import { Flex, Box } from '@rebass/grid';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { actions as loginActions } from '../../redux/modules/login.module';
import { getUserRole, getRoleType, getUserDetails } from '../../redux/selector';
import {
  Button,
  Input,
  ErrorContainer,
} from '../../components';
import {
  LoginWrapper,
  LoginInputWrapper,
  LoginLogo,
  LoginPanelBox
} from './LoginContainer.styles';
import { logo } from '../../utils/constants';

const loginShema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter Email Address'),
  password: Yup.string().required('Please enter Password'),
});

const LoginContainer = ({
  history,
  userRole,
  loginRequest,
  isLoginError,
  roleType,
  userDetails
}) => {
  const didMountRef = useRef(false);
  const submitLoginRef = useRef(null);

  const onLoginClick = (values) => {
    loginRequest({
      params: {
        ...values,
      },
    });
  };


  const handleUserKeyPress = useCallback((event) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      if (submitLoginRef.current) {
        let form = submitLoginRef.current;
        let button = form.querySelector('button[type="submit"]');
        button.click();
      }
    }
  }, []);
  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  useEffect(() => {
    const windowBodyClassList = document.body.classList;
    if (!windowBodyClassList.contains('login')) {
      windowBodyClassList.add('login');
    }
    return () => {
      windowBodyClassList.remove('login');
    };
  }, []);

  const goToDashboard = () => {
    history.push('/dashboard');
  };

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (userDetails && Object.keys(userDetails).length > 0 && userDetails.is_staff) {
        goToDashboard();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);
  return (
    <LoginWrapper
      width={1}
      height="100vh"
      alignItems="center"
      justifyContent="center"
      pt="20px"
    >
      <LoginPanelBox>
        <LoginLogo
          style={{width: '250px'}}
          src={logo['01']}
          alt="mio-logo"
        />
        <h6>Admin Login</h6>
        <Formik
          validateOnChange={false}
          initialValues={{ email: '', password: '' }}
          onSubmit={onLoginClick}
          validationSchema={loginShema}
        >
          {({ handleSubmit, handleChange, values, errors = {} }) => (
            <form name="loginform" ref={submitLoginRef}>
              <Flex width={1} justifyContent="center">
                <Box width={1}>
                  {(isLoginError || (userDetails && Object.keys(userDetails).length > 0 && !userDetails.is_staff)) && (
                    <ErrorContainer align="center">
                      Invalid Email Id or Password
                    </ErrorContainer>
                  )}
                  <LoginInputWrapper>
                    <Input
                      name="email"
                      id="email"
                      type="text"
                      placeholder="Email Address"
                      onChange={handleChange}
                      value={values.email}
                      error={errors.email}
                    />
                    <br />
                    <Input
                      name="password"
                      id="password"
                      type="password"
                      placeholder="Password"
                      onChange={handleChange}
                      value={values.password}
                      error={errors.password}
                    />
                  </LoginInputWrapper>
                </Box>
              </Flex>
              <Button type="submit" onClick={handleSubmit} upperCase>
                Login
              </Button>
            </form>
          )}
        </Formik>
      </LoginPanelBox>
    </LoginWrapper>
  );
};

const mapStateToProps = (state) => ({
  userRole: getUserRole(state),
  isLoginError: state.user.isError,
  userDetails: getUserDetails(state),
  roleType: getRoleType(state),
});

const mapDispatchToProps = {
  loginRequest: loginActions.api.login.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
