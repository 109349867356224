import React from 'react';
import withDashboardLayout from '../../hoc/withDashboardLayout';
import {
  AdminDashboardContainer,
} from '../../containers';

const DashboardLayout = (props) => {

  return <AdminDashboardContainer {...props} />;

};


export default withDashboardLayout(DashboardLayout);
