import { Flex } from "@rebass/grid";
import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  UploadFileButton,
} from "../../components";
import { toBase64, dataURLtoFile } from "../../utils";
import {
  RadioInput,
  RadioInputLabel,
  TextArea,
} from "./AddContentContainer.styles";

const ImageSelectionPopup = ({
  handleClose,
  imageUrl,
  content_id,
  updateImage,
}) => {
  const [customImage, setCustomImage] = useState();
  const [summary, setSummary] = useState("");
  const [personalizationType, setPersonalizationType] =
    useState("no_personalization");
  const textAreaRef = useRef(null);

  const onPaste = (e) => {
    const innerTextLength = textAreaRef.current.innerText.length;
    const maxLength = 120;
    let content;
    e.preventDefault();
    if (e.clipboardData) {
      content =
        maxLength &&
        maxLength !== innerTextLength &&
        e.clipboardData
          .getData("text/plain")
          .substring(0, maxLength - innerTextLength);
      if (content.length <= maxLength) {
        document.execCommand("insertText", false, content);
        return false;
      }
    }
  };

  const onKeyPress = (e) => {
    const maxLength = 120;
    if (maxLength > 0 && textAreaRef.current.innerText.length > maxLength - 1) {
      e.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const onUploadImage = () => {
    if (customImage) {
      toBase64(customImage).then((file) => {
        const upfile = dataURLtoFile(file, "filename.png");
        //   reqObj.media1 = file;
        let payload = {
          content_id: content_id,
          media1: upfile,
          message: summary,
          personalization:
            personalizationType === "no_personalization" ? "false" : "true",
        };
        updateImage(payload)
          .then((res) => {
            handleClose();
          })
          .catch((err) => {
            console.log("err", err);
          });
      });
    } else {
      //   reqObj.media1 = file;
      let payload = {
        content_id: content_id,
        message: summary,
        personalization:
          personalizationType === "no_personalization" ? "false" : "true",
      };
      updateImage(payload)
        .then((res) => {
          handleClose();
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  return (
    <Modal
      bgColor="white"
      height="75%"
      size="medium-small"
      //   onRequestClose={handleClose}
      //   shouldCloseOnOverlayClick
      isOpen
    >
      <ModalHeader id="header" style={{ textAlign: "center" }}>
        <Flex fontSize="0.9rem" color="black" style={{ fontWeight: "600" }}>
          Select Image
        </Flex>
        <ModalCloseButton onClick={handleClose} size="25px" />
        <hr style={{ width: "100%" }} />
      </ModalHeader>
      <ModalBody style={{ overflowY: "auto", height: "inherit" }}>
        <img
          width="65%"
          height="45%"
          className="img-wrapper"
          src={customImage ? window.URL.createObjectURL(customImage) : imageUrl}
          alt=""
          id="pdf_img"
          style={{ borderRadius: "5px", width: "85%" }}
        />
        <Flex width="100%" mt="18px" flexDirection={"column"}>
          <div style={{color: "#6351ed", marginBottom: "10px"}}>
            {summary.length > 120
              ? "Summary should not be more than 120 characters"
              : ""}
          </div>
          <TextArea
            placeholder="Please enter your summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            onPaste={onPaste}
            onKeyDown={onKeyPress}
            ref={textAreaRef}
          />
        </Flex>

        <Flex width="100%" mt="30px">
          <Flex
            alignItems="center"
            justifyContent="center"
            marginRight="5px"
            // ml="25px"
          >
            <RadioInput
              type="radio"
              name="personalizationType"
              id="personalization"
              onClick={() => setPersonalizationType("personalization")}
              checked={personalizationType === "personalization"}
              style={{ cursor: "pointer" }}
            />
            <RadioInputLabel
              htmlFor="personalization"
              style={{ cursor: "pointer" }}
            >
              Personalization
            </RadioInputLabel>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            marginRight="5px"
            // ml="25px"
          >
            <RadioInput
              type="radio"
              name="personalizationType"
              id="no_personalization"
              onClick={() => setPersonalizationType("no_personalization")}
              checked={personalizationType === "no_personalization"}
              style={{ cursor: "pointer" }}
            />
            <RadioInputLabel
              htmlFor="no_personalization"
              style={{ cursor: "pointer" }}
            >
              No Personalization
            </RadioInputLabel>
          </Flex>
        </Flex>

        <div
          style={{
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
            padding: "10px",
          }}
        >
          <hr style={{ width: "100%" }} />

          <Flex justifyContent="flex-end" py="10px">
            {!customImage && (
              <UploadFileButton
                forLabel="BtnBrowseHidden"
                id="LblBrowse"
                styles={{ borderRadius: "4px" }}
                color="#fff"
                bgColor="#4E6780"
                margin="0px 10px"
                borderColor="#33b21b"
              >
                Upload new image
              </UploadFileButton>
            )}
            <Button
              styles={{ borderRadius: "4px" }}
              color="#fff"
              bgColor="#4E6780"
              margin="0px 10px"
              borderColor="#4E6780"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
            {customImage && (
              <Button
                styles={{ borderRadius: "4px" }}
                color="#fff"
                bgColor="#4E6780"
                margin="0px 10px"
                borderColor="#4E6780"
                onClick={() => {
                  let imgInput = document.getElementById("BtnBrowseHidden");
                  imgInput.value = null;
                  setCustomImage();
                }}
              >
                Restore original image
              </Button>
            )}
            <input
              type="file"
              id="BtnBrowseHidden"
              name="files"
              style={{ display: "none" }}
              onChange={(event) => {
                setCustomImage(event.target.files[0]);
              }}
            />

            <Button
              styles={{ borderRadius: "4px" }}
              color="#fff"
              bgColor="#33b21b"
              margin="0px 10px"
              borderColor="#33b21b"
              disabled={summary.length > 120 || summary.length === 0}
              //   disabled={isEmpty(selectedContent)}
              onClick={() => onUploadImage()}
            >
              Save
            </Button>
          </Flex>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ImageSelectionPopup;
