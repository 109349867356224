import { takeLatest, all, call, put } from 'redux-saga/effects';
import { get } from 'lodash';
import { actions as myEditorContentActions } from '../modules/myEditorContent.module'; 
import { getAllMyEditorContent, deleteMyEditorContent } from '../../services/myEditorContent';

export function* myEditorContentRequest() {
  try {
    console.log('check');
    const apiCall = getAllMyEditorContent;
    console.log('eeeeeee');
    const response = yield call(apiCall);
    const data = get(response, 'result.data', {});
    yield put(myEditorContentActions.api.myEditorContent.response(data));
  } catch (error) {
    yield put(myEditorContentActions.api.myEditorContent.error(error));
  }
}

function* deleteMyEditorContentRequest({ payload }) {
    try {
      const { deleteId } = payload;
      const apiCall = deleteMyEditorContent;
      yield call(apiCall, deleteId);
      yield put(myEditorContentActions.api.myEditorContent.request());
    } catch (error) {
      yield put(myEditorContentActions.api.myEditorContent.error(error));
    }
  }

function* myEditorContentSaga() {
  yield all([
      takeLatest(myEditorContentActions.api.myEditorContent.request, myEditorContentRequest),
      takeLatest(myEditorContentActions.api.myEditorContent.delete, deleteMyEditorContentRequest)
    ]);
}

export default myEditorContentSaga;