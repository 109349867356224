import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { ResponsiveImg } from '../../components';

export const LoginWrapper = styled(Flex)`
  background-color: #fff;
  font-size: 12px;
  input[type='checkbox'] {
    position: relative;
    top: 3px;
  }
  button {
    font-size: 12px;
  }
  a {
    text-decoration: none;
  }

  h6 {
    font-weight: 500;
    line-height: 1.1;
    margin-top: 10px;
    margin-bottom: 25px;
    font-size: 14px;
    &.login-header {
      margin-top: 30px;
    }
  }
  label {
    cursor: pointer;
  }
`;

export const LoginPanelBox = styled(Box)`
  width: 280px;
`;

export const LoginInputWrapper = styled.div`
  input[type='text'] {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #f1f1f1;
  }
  .validation-error {
    text-align: left;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 2px;
  }
`;

export const LoginLogo = styled(ResponsiveImg)`
  width: 160px;
`;

export const LoginFooter = styled(Flex)`
  position: fixed;
  bottom: 0px;
  padding: 5px;
  }
`;







