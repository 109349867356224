import { handleActions } from "redux-actions";
import { apiModuleDefaultState, createApiActions } from "./helpers";

export const actions = createApiActions("theme");

const reducer = handleActions(
  {
    [actions.api.theme.request]: (_, { payload }) => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.theme.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.theme.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;
