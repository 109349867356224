import styled from 'styled-components';

import theme from '../CommonStyles/colors';
import { ResponsiveImg } from '../CommonStyles';

export const HeaderDiv = styled.div`
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid ${theme.color.border.primary};
  nav {
    height: 100%;
  }
  position: ${(props) => (props.screenWidth < 768 ? 'fixed' : 'static')};
  z-index: ${(props) => (props.screenWidth < 768 ? '11' : '0')};
`;

export const HeaderUl = styled.ul`
  display: flex;
  list-style: none;
  margin: 0px;
  height: 100%;
`;

export const HeaderLI = styled.li`
  height: 100%;
  margin: ${({ type }) => (type === 'sidebar' ? '1rem 1.5rem' : ' 0 1.5em')};
  a {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 9px;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    font-size: 20px;
    ${({ selected }) => (selected ? 'border-bottom: 2px solid #6f53a7;' : '')}
  }
  i {
    display: block;
    width: 50px;
    font-size: 16px;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

export const HeaderLogo = styled(ResponsiveImg)`
  width: 125px;
  // width: 95px;
`;

export const IconTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;