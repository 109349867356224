import { createActions } from 'redux-actions';

export const apiModuleDefaultState = {
  isFetching: false,
  isError: false,
};

export const createApiActions = (apiName) =>
  createActions({
    api: {
      [apiName]: {
        REQUEST: (payload) => payload,
        RESPONSE: (payload) => payload,
        ERROR: (payload) => payload,
        DELETE: (payload) => payload,
        CREATE: (payload) => payload,
        UPDATE: (payload) => payload,
        FILTER: (payload) => payload
      },
    },
  });