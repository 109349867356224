import styled from "styled-components";
import { MioTextArea } from "../../components";

export const TextArea = styled(MioTextArea)`
  text-align: left;
  min-height: 150px;
  padding: 10px;
  margin-bottom: 0px;
  ::placeholder {
    text-align: center;
  }
  font-family: "Poppins";
`;

export const RadioInput = styled.input`
  border: 2.7px solid white;
  box-shadow: 0 0 0 1px #aaa;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "")};

  &:checked {
    background-color: ${({ disabled }) => (!disabled ? "#6f53a7" : "#ccc")};
    box-shadow: 0 0 0 1px #aaa;
  }
`;

export const RadioInputLabel = styled.label`
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 5px;
  font-family: "Poppins";
  cursor: ${({ disabled }) => (!disabled ? "pointer" : "")};
`;
