/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from "@rebass/grid";
import React, { useState, useEffect } from "react";
import { Button, LoaderWrapper } from "../../components";
import { connect } from "react-redux";
// import "./style.css";
import { actions as myEditorContentActions } from "../../redux/modules/myEditorContent.module";
import { get } from "lodash";
// import ImageSelectionPopup from "./ImageSelectionPopup";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {DeleteMyEditorContentModal} from './DeleteMyEditorContentModal'

const MyEditorContentContainer = ({myEditorContentData,myEditorContentRequest, deleteMyEditorContentRequest, isFetching}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);

    useEffect(() => {
        myEditorContentRequest();
    },[])

    const deleteButtonHandler = (id) => {
        setIsModalOpen(true);
        const newDeleteId = id.split('_')[2];
        setDeleteId(newDeleteId);
    }

    return (
            <Flex
                width="90%"
                flexDirection="column"
                margin="40px auto"
                fontFamily="Poppins"
            >
            <Flex width="90%" justifyContent="right" pb="15px" margin="0 auto">
                <Link to="/add-content">
                    <Button
                    type="submit" 
                    >
                    ADD EDITOR CONTENT
                    </Button>
                </Link>
            </Flex>
<Flex
                data-testid="campaignlist-item"
                justifyContent="space-between"
                alignItems="center"
                flexDirection="column"
                width="90%"
                margin="0 auto"
                // width={campaignDetailsView ? '38%' : '100%'}
                style={{
                border: '1px solid #DBE0E6',
                borderRadius: '4px',
                // borderRight: `6px solid ${statusColorCodes[status]}`,
                // margin: '80px 0',
                boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
                backgroundColor : '#fff',
                }}
            >
                <h2>EDITOR CONTENT</h2>
                <Flex
                        width="100%"
                        justifyContent="space-between"
                        padding="10px"
                        alignItems="center"
                        fontSize="20px"
                        style={{
                                border: '1px solid #DBE0E6'}}
                    >
                        <div>TOPIC</div>
                        <div>ACTION</div>
                </Flex>
        <LoaderWrapper isLoading={isFetching}>
                
                {get(myEditorContentData, 'feed', []).map((item) => 

                    <Flex
                        width="100%"
                        justifyContent="space-between"
                        padding="10px 25px 10px 10px"
                        alignItems="center"
                        fontSize="18px"
                        style={{
                                border: '1px solid #DBE0E6'}}
                    >
                        <div>{item?.auxiliary?.title}</div>
                        <FontAwesomeIcon
                            icon={faTrash}
                            color="#c95cfc"
                            size="1x"
                            style={{ cursor: "pointer", marginRight: "4px" }}
                            onClick={() => deleteButtonHandler(item?.id)}
                        />
                    </Flex>
                
                )}
                </LoaderWrapper>
                {isModalOpen &&
                 <DeleteMyEditorContentModal 
                     open={isModalOpen}
                     handleClose={() => setIsModalOpen(false)}
                     deleteId={deleteId}
                     deleteMyEditorContentRequest={deleteMyEditorContentRequest}
                 />}
            </Flex>
            </Flex>
            
        
    )
}


const mapStateToProps = (state) => ({
    // userData: state.user.data,
    // themeData: state.theme,
    myEditorContentData: state.myEditorContent.data,
    isFetching : state.myEditorContent.isFetching,
  });
  
  const mapDispatchToProps = {
    myEditorContentRequest: myEditorContentActions.api.myEditorContent.request,
    deleteMyEditorContentRequest: myEditorContentActions.api.myEditorContent.delete,
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyEditorContentContainer);