import { Flex } from "@rebass/grid";
import React, { useState, useEffect } from "react";
import { Button, Input, LoaderWrapper, MioSelect } from "../../components";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { DatePickerWrapper } from "./CreateUserContainer.styles";
import { getEnterpriseList, getFirmsLists, getZipcodeInfo, postUser } from "../../services/create-user";
import parsePhoneNumber, { isValidPhoneNumber } from "libphonenumber-js";
import { isEmpty } from "lodash";

const CreateUserContainer = ({ userData }) => {
  // const [showDropdown, toggleDropdown] = useState(false);
  const [enterpriseLists, setEnterpriseLists] = useState([]);
  const [firmLists, setFirmLists] = useState([]);
  const [selectedEnterpriseList, setSelectedEnterpriseList] = useState("");
  const [selectedFirmList, setSelectedFirmList] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [dob, setDob] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState("");
  const [userFormData, setUserFormData] = useState({
    timezone: "",
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    zipcode: "",
    city: "",
    state: "",
    mobile: "",
    language: "en",
    status: "active",
    investor_type: "Other/Curious",
    aum: "",
    membership_type: "paid",
    subscription_type: "platform_sponsored",
    account_type: "",
    allow_sharing_on_my_behalf: "",
    allow_autoscheduling_on_my_behalf: "true",
    registration_method: "direct",
    password_confirmation: "",
    role_type: "",
  });

  const aumOptions = [
    "Under $100,000",
    "$100,000 to $1 million",
    "$1 million to $10 million",
    "$10 million to $50 million",
    "$50 million to $100 million",
    "$100 million to $500 million",
    "$500 million to $1 billion",
    "More than $1 billion",
  ];

  const handleChange = (e) => {
    setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
  };

  const handlePhoneNumberChange = (e) => {
    setErrorMessage("");

    let formattedNumber = e.target.value;
    if (e.target.value.length > 1) {
      let phoneNumber = parsePhoneNumber(e.target.value, "US");

      if (phoneNumber) {
        formattedNumber = phoneNumber.formatInternational();
      }
    }

    setUserFormData({ ...userFormData, [e.target.name]: formattedNumber });
  };

  const handleZipcodeChange = (e) => {
    setErrorMessage("");
    setUserFormData({ ...userFormData, [e.target.name]: e.target.value });
    if (e.target.value && e.target.value.length >= 4) {
      getZipcodeInfo(e.target.value)
        .then((res) => {
          if (res?.result?.data) {
            let info = res?.result?.data;
            let city = info.city;
            let state = info.state;
            updateZipcodeChange(e.target.value, city, state);
          } else {
            updateZipcodeChange(e.target.value, "", "");
          }
        })
        .catch((err) => {
          updateZipcodeChange(e.target.value, "", "");
        });
    }
  };

  const updateZipcodeChange = (zipcode, city, state) => {
    setUserFormData({
      ...userFormData,
      zipcode: zipcode,
      city: city,
      state: state,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessage("");
    setMessage("");

    if (userFormData.city === "" || userFormData.state === "") {
      setErrorMessage("Please enter valid zipcode");
      setLoading(false);
      return;
    }

    if (userFormData.password.length < 8 || userFormData.password.length > 15) {
      setErrorMessage("Password must be between 8 to 15 characters");
      setLoading(false);
      return;
    }

    if (userFormData.password !== userFormData.password_confirmation) {
      setErrorMessage("Password and confirm password dosen't match");
      setLoading(false);
      return;
    }

    let phoneNumber = userFormData.mobile;

    if (!isValidPhoneNumber(phoneNumber, "US")) {
      setErrorMessage("The mobile number entered is not a valid US number");
      setLoading(false);
      return;
    }

    userFormData.password = encodeURIComponent(userFormData.password).replace(
      /'/g,
      "%27"
    );

    const userData = userFormData;
    delete userData.password_confirmation;

    if (dob) {
      userData.dob = dob;
    }

    if (userData.business_category === "1") {
      delete userData.business_category;
    }

    if (userData.role_type === "1") {
      delete userData.role_type;
    }

    if (!isEmpty(selectedFirmList) && selectedFirmList) {
      userData.cid = selectedFirmList;
    } 

    if (isEmpty(selectedFirmList) && selectedEnterpriseList) {
      userData.cid = enterpriseLists.filter((e) => e.id === selectedEnterpriseList)[0].id_hash
    }

    if (userData.turnkey === "false" || !userData.turnkey) {
      delete userData.as_partner_email;
    }

    postUser(userData)
      .then((res) => {
        if (res.result.success) {
          setUserFormData({
            timezone: "",
            email: "",
            password: "",
            first_name: "",
            last_name: "",
            zipcode: "",
            state: "",
            city: "",
            mobile: "",
            language: "en",
            status: "active",
            investor_type: "Other/Curious",
            aum: "",
            membership_type: "paid",
            subscription_type: "platform_sponsored",
            account_type: "",
            allow_sharing_on_my_behalf: "",
            allow_autoscheduling_on_my_behalf: "true",
            registration_method: "direct",
            password_confirmation: "",
            role_type: "",
            
          });
          setSelectedEnterpriseList("");
          setSelectedFirmList("");
          setFirmLists("");
          setLoading(false);
          setMessage("Successfully created user");
        } else {
          setLoading(false);
          setUserFormData({ ...userFormData, password_confirmation: "" });
        }
      })
      .catch((err) => {
        setLoading(false);
        setUserFormData({ ...userFormData, password_confirmation: "" });
        if (err.code === 2300) {
          setErrorMessage(err.message);
        }
      });
  };

  // const handleDropdownChange = (e) => {
  //   setListId(e.target.value);
  //   // setInviteData(e.target.value)
  // };

  // const [listId, setListId] = useState("-1");

  // const getOffset = (timeZone = 'UTC', date = new Date()) => {
  //   try {
  //     const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  //     const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
  //     const timeZoneMin = (tzDate.getTime() - utcDate.getTime()) / 6e4;
  //     if (timeZoneMin !== 0) {
  //       const hours = `0${Math.floor(timeZoneMin / 60)}`.slice(-2);
  //       const minutes = `0${timeZoneMin % 60}`.slice(-2);
  //       return `GMT${timeZoneMin > 0 ? '+' : ''}${hours}:${minutes}`
  //     } else {
  //       return `GMT+00:00`
  //     }
  //   } catch {
  //     return 'invalid'
  //   }

  // }

  useEffect(() => {
    fetchEnterpriseList();
  }, []);

  const fetchEnterpriseList = async () => {
    try {
      const { data } = await getEnterpriseList();
      setEnterpriseLists(data);
    } catch (error) {
      console.log("error--->", error);
    }
  };

  const fetchFirmsLists = (val) => {
    const payload = { id: val };
    getFirmsLists(payload)
      .then((res) => {
        if (res?.success) {
          setFirmLists(res?.result);
        }
      })
      .catch((error) => {
        console.log("error-->", error);
      });
  };

  const handleEnterpriseChange = (e) => {
    setSelectedEnterpriseList(e.target.value);
    if (e.target.value) {
      fetchFirmsLists(e.target.value);
    } 
  };

  const handleFirmChange = (e) => {
    setSelectedFirmList(e.target.value);

  };


  return (
    <Flex width="100%" flexDirection="column" pt="25px">
      <form onSubmit={handleSubmit}>
        <Flex
          width="100%"
          style={{ fontWeight: "600", justifyContent: "center" }}
        >
          CREATE USER
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <Input
              type="text"
              name="first_name"
              value={userFormData.first_name}
              containerWidth="50%"
              placeholder="First Name*"
              onChange={handleChange}
              required={true}
            />
            <Input
              type="text"
              name="last_name"
              value={userFormData.last_name}
              containerWidth="48%"
              placeholder="Last Name*"
              onChange={handleChange}
              required={true}
            />
          </Flex>
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Input
            type="text"
            name="email"
            value={userFormData.email}
            containerWidth="50%"
            placeholder="Email Address*"
            onChange={handleChange}
            required={true}
          />
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <Input
              type="text"
              name="password"
              value={userFormData.password}
              containerWidth="50%"
              placeholder="Password(8-15 chars)*"
              onChange={handleChange}
              required={true}
            />
            <Input
              type="text"
              name="password_confirmation"
              value={userFormData.password_confirmation}
              containerWidth="48%"
              placeholder="Confirm Password*"
              onChange={handleChange}
              required={true}
            />
          </Flex>
        </Flex>
        {/* <Flex width="100%" pt="15px" justifyContent="center">
          <Input
            type="text"
            name="firm_name"
            value={userFormData.firm_name}
            containerWidth="50%"
            placeholder="Enter Firm Name"
            onChange={handleChange}
            required={true}
          />
        </Flex> */}
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <Input
              type="text"
              name="zipcode"
              containerWidth="50%"
              value={userFormData.zipcode}
              placeholder="Enter Zipcode"
              onChange={handleZipcodeChange}
              maxLength="6"
              required={true}
            />
            <Input
              type="text"
              name="city"
              containerWidth="48%"
              value={userFormData.city}
              placeholder="Enter City"
              onChange={handleChange}
              required={true}
              disabled
            />
          </Flex>
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <Input
              type="text"
              name="state"
              containerWidth="50%"
              value={userFormData.state}
              placeholder="Enter State"
              onChange={handleChange}
              required={true}
              disabled
            />

            <Input
              type="text"
              name="mobile"
              containerWidth="48%"
              value={userFormData.mobile}
              placeholder="Enter Mobile Number"
              maxLength="15"
              onChange={handlePhoneNumberChange}
              required={true}
            />
          </Flex>
        </Flex>
        <DatePickerWrapper width="100%" pt="15px" justifyContent="center">
          <DatePicker
            selected={dob}
            showMonthDropdown={true}
            showYearDropdown={true}
            onChange={(date) => setDob(date)}
          />
        </DatePickerWrapper>

        {/* <Flex width="100%" pt="15px" justifyContent="center">
        <Input
          type="text"
          name="timezone"
          id="timezone"
          onChange={(e) => {
            toggleDropdown(true);
            handleChange(e);
          }}
          onFocus={() => toggleDropdown(true)}
          value={userFormData.timezone} 
          placeholder="Enter Timezone"
          containerWidth="50%"
          isInputWithFloatingLabel={true}
          required={true}
          autoComplete={'off'}
        />

        {userData && userData.details && userData.details.user && userData.details.user.available_timezones && showDropdown ? (
          <div style={{ position: "relative", right: "0px", padding: "0px", top: '36px' }}>
            <ul
              style={{
                width: "544px",
                fontSize: "14px",
                maxHeight: "150px",
                overflowY: "auto",
                position: "absolute",
                listStyleType: "none",
                right: "0px",
                border: "1px solid #ddd",
                borderTop: "0px",
                marginTop: "0px",
                paddingLeft: "0px",
                borderRadius: "4px",
              }}
            >
              {userData.details.user.available_timezones
                .filter((timezone) =>
                  timezone.toLowerCase().includes(userFormData.timezone.toLowerCase())
                )
                .map((timezone) => (
                  <li
                    style={{
                      backgroundColor: "white",
                      width: "100%",
                      cursor: "pointer",
                      borderBottom: "1px solid #ddd",
                      padding: "4px 5px",
                    }}
                    onClick={() => {
                      toggleDropdown(false);
                      setUserFormData({...userFormData, timezone: timezone})
                    }}
                  >
                    {timezone}
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
      </Flex> */}

        <Flex>
          <div className="invite-div">
            <div className="flex-container">
              <MioSelect
                id="timezone"
                name="timezone"
                required={true}
                value={userFormData.timezone}
                style={{
                  marginLeft: "272px",
                  marginTop: "15px",
                  width: "67.5%",
                }}
                onChange={(e) => {
                  handleChange(e);
                }}
              >
                <option value="">Select Timezone</option>
                {userData.details &&
                  userData.details.user &&
                  Object.keys(userData.details.user.google_timezones)
                    .filter(
                      (key) => userData.details.user.google_timezones[key]
                    )
                    .map((key, index) => (
                      <option key={index} value={`${key}`}>
                        {`${userData.details.user.google_timezones[key]}: ${key}`}
                      </option>
                    ))}
              </MioSelect>
            </div>
          </div>
        </Flex>

        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <MioSelect
              width="50%"
              name="aum"
              value={userFormData.aum}
              onChange={handleChange}
              required={true}
            >
              <option value={""}>Assets Under Management/Advice</option>
              {aumOptions.map((item, index) => (
                <option value={item}>{item}</option>
              ))}
            </MioSelect>
          </Flex>
        </Flex>

        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <MioSelect
              width="50%"
              name="account_type"
              value={userFormData.account_type}
              onChange={handleChange}
              required={true}
            >
              <option value={""}>Account Type</option>
              <option value={"live"}>Live</option>
              <option value={"internal"}>Internal</option>
              <option value={"testing"}>Testing</option>
            </MioSelect>
          </Flex>
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <MioSelect
              width="50%"
              id="ent_list"
              name="ent_list"
              value={selectedEnterpriseList}
              required={true}
              onChange={handleEnterpriseChange}
            >
              <option value={""}>Select Company</option>
              {!isEmpty(enterpriseLists) &&
                enterpriseLists.map((enterpriseList, index) => {
                  return (
                    <option key={index} value={enterpriseList?.id} >
                      {enterpriseList?.name}
                    </option>
                  );
                })}
            </MioSelect>
            <MioSelect
              width="48%"
              name="firm_list"
              value={selectedFirmList}
              onChange={handleFirmChange}
              required={false}
            >
              <option value="">Select Firms</option>
              {!isEmpty(firmLists) &&
                firmLists.map((firmList, index) => (
                  <option key={index} value={firmList?.cid}>
                    {firmList?.name}
                  </option>
                ))}
            </MioSelect>
          </Flex>
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <MioSelect
              width="30%"
              name="role_type"
              value={userFormData.role_type}
              onChange={handleChange}
              required={true}
            >
              <option value={""}> Select Role Type</option>
              {/* <option value={"admin"}>Admin</option> */}
              <option value={"general"}>Advisor</option>
            </MioSelect>
          </Flex>
        </Flex>
        <Flex width="100%" pt="15px" justifyContent="center">
          <Flex width="50%" justifyContent="space-between">
            <MioSelect
              width="50%"
              name="allow_sharing_on_my_behalf"
              value={userFormData.allow_sharing_on_my_behalf}
              onChange={handleChange}
              required={true}
            >
              <option value={""}>Allow Sharing on My Behalf</option>
              <option value={"true"}>True</option>
              <option value={"false"}>False</option>
            </MioSelect>
          </Flex>
        </Flex>
        {userFormData.turnkey === "true" && (
          <Flex width="100%" pt="15px" justifyContent="center">
            <Flex width="50%" justifyContent="space-between">
              <Input
                type="text"
                name="as_partner_email"
                value={userFormData.as_partner_email}
                containerWidth="50%"
                placeholder="AS Partner's Email"
                onChange={handleChange}
                required={true}
              />
            </Flex>
          </Flex>
        )}
        <Flex width="100%" justifyContent="center" pb="15px">
          <LoaderWrapper isLoading={loading}>
            <Button type="submit">Create User</Button>
          </LoaderWrapper>
        </Flex>
        <Flex width="100%" justifyContent="center" pb="15px">
          {message}
        </Flex>
        <Flex width="100%" justifyContent="center" pb="15px">
          {errorMessage}
        </Flex>
      </form>
    </Flex>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
});

export default connect(mapStateToProps, null)(CreateUserContainer);
