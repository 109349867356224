import styled from "styled-components";
import Modal from "react-modal";
// import { themesData } from '../../utils/constants';

export const HeaderContainer = styled.div`
  padding: 10px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? paddingBottom : "10px"};
  border-bottom: 0px solid #e4e4e4;
  text-align: center;
  background-color: transparent;
  ${
    "" /* color: ${({ fontColor }) => fontColor || themesData.purpleishBlue}; */
  }
  font-family: 'Poppins';
  font-size: ${({ fontSize }) => fontSize || "18px"};
  font-weight: ${({ type }) =>
    type === "customize-email" ? "bold" : "normal"};
`;

export const ModalContent = styled.div`
  color: ${({ col }) => (col ? "#6351ed" : "#848484")};
  text-align: center;
  font-size: 13px;
  font-family: "Poppins" !important;
  padding: ${({ padding }) => padding || "15px !important"};
`;

export const ModalBottom = styled.div`
  padding: 15px;
  text-align: center;
  border-top: 1px solid #e5e5e5;
  background-color: #ececec;
`;

export const ModalFooterButton = styled.div`
  background-color: #6351ed;
  border: 1px solid #6351ed;
  color: #fff;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  margin: 2px;
  border-radius: 4px;
  font-family: "Poppins";

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
    background-color: gray;
    border: 0px solid gray;
  `}
`;

export const CustomModal = styled(Modal)`
  top: ${props => props.isDeleteMyEditorContent ? "45%" : "11%"};
  margin: ${({ margin }) => (margin ? margin : "0 auto")};
  border: none;
  position: relative;
  z-index: 12;
  box-shadow: ${({ noBoxShadow }) =>
    noBoxShadow ? "" : "0 5px 15px rgba(0, 0, 0, 0.5)"};
  border-radius: 7px;
  padding: ${({ padding }) => padding || "7px"};
  background-color: ${({ type, bgColor }) =>
    bgColor
      ? bgColor
      : type === "sponsored"
      ? ""
      : type === "invite-clients"
      ? "#f3f1f1"
      : type === "newsletter"
      ? ""
      : "#ececec"};
  background-image: ${({ type }) =>
    type === "sponsored" || type === "invite-clients"
      ? "linear-gradient(to bottom, #ececec, #ffffff 78%, #f7f7f7)"
      : ""};
  border: ${({ borderColor }) =>
    borderColor ? `1px solid ${borderColor}` : "1px solid rgba(0, 0, 0, 0.2)"}; 
  width: ${({ size }) => {
    if (size === "large") {
      return "100%";
    } else if (size === "big") {
      return "89%";
    } else if (size === "medium") {
      return "60%";
    } else if (size === "big83") {
      return "83%";
    } else if (size === "medium65") {
      return "65%";
    } else if (size === "medium68") {
      return "68%";
    } else if (size === "medium75") {
      return "75%";
    } else if (size === "medium77") {
      return "77%";
    } else if (size === "big92") {
      return "92%";
    } else if (size === "medium800") {
      return "800px";
    } else if (size === "newsletter-compliance") return "983px";
    else if (size === "small") {
      return "389px";
    } else if (size === "medium-small") {
      return "38%";
    } else if (size === "flexible") return "auto";
    return "30%";
  }};
  height: ${({ height, size }) =>
    height ? height : size === "big" ? "95%" : ""}};
  ${({ width }) => (width ? `width:${width};` : "")};
  @media (max-width: 768px) {
    width: 92%;
  }
`;

export const ModalTriggerContainer = styled.a`
  cursor: pointer;
  font-size: 12px;
  float: right;
`;

export const ModalCloseWrapper = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;
