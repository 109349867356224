/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback } from 'react';
import {
  CustomModal as Modal,
  HeaderContainer as ModalHeader,
  ModalContent as ModalBody,
  ModalBottom as ModalFooter,
  ModalFooterButton as ModalButton,
  ModalTriggerContainer as ModalTrigger,
  ModalCloseWrapper,
} from './Modal.styles';
import { DripIconButton } from '../';

const ModalCloseButton = ({ onClick, color, size }) => {
  const handleEscapeKeyPress = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 27) {
      onClick();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKeyPress);
    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, [handleEscapeKeyPress]);

  return (
    <ModalCloseWrapper>
      <DripIconButton
        icon="cross"
        onClick={onClick}
        color={color}
        fontSize={size}
      />
    </ModalCloseWrapper>
  );
};

export {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  ModalTrigger,
  ModalCloseButton,
};
