import { Flex } from "@rebass/grid";
import React from "react";
import {
  DelayedColumn,
  DelayedRowField,
  DelayedColumnHeader,
} from "./DelayedCampaignRow.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

export const DelayedCampaignRow = ({ campaign, index }) => {
  // console.log('CAMPAIGN - - - - ', campaign);
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      padding="15px 0px"
      style={{ backgroundColor: index % 2 === 0 ? "#fff" : "#d4d4d4" }}
    >
      <DelayedColumn style={{ width: "18%" }}>
        {campaign.campaign_name || "-"}
      </DelayedColumn>
      <DelayedColumn>{campaign.advisor_name}</DelayedColumn>
      <DelayedColumn style={{ width: "10%" }}>
        {campaign.destination_snetwork}
      </DelayedColumn>
      <DelayedColumn style={{ width: "10%" }}>
        {campaign.total_count}
      </DelayedColumn>
      <DelayedColumn>{campaign.scheduled_time}</DelayedColumn>
      <DelayedColumn style={{ width: "10%" }}>
        {campaign.status
          ? `${campaign.status[0].toUpperCase()}${campaign.status.slice(1)}`
          : "-"}
      </DelayedColumn>
      <DelayedColumn>{campaign.timezone}</DelayedColumn>
      <DelayedColumn style={{ width: "18%" }}>
        {campaign.delay_time}
      </DelayedColumn>
    </Flex>
  );
};

export const DelayedCampaignRowHeader = ({ sortResults }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      width="100%"
      padding="15px 0px 15px 8px"
      style={{ borderBottom: "1px solid #49484a" }}
    >
      <DelayedColumnHeader style={{ width: "18%" }}>
        <DelayedRowField>Campaign Name</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="campaign_name" />
      </DelayedColumnHeader>
      <DelayedColumnHeader>
        <DelayedRowField>Advisor</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="advisor_name" />
      </DelayedColumnHeader>
      <DelayedColumnHeader style={{ width: "10%" }}>
        <DelayedRowField>Platform</DelayedRowField>
        <SortButtons
          sortHandler={sortResults}
          sortField="destination_snetwork"
        />
      </DelayedColumnHeader>
      <DelayedColumnHeader style={{ width: "10%" }}>
        <DelayedRowField>Count</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="total_count" />
      </DelayedColumnHeader>
      <DelayedColumnHeader>
        <DelayedRowField>Schedule Time</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="scheduled_time" />
      </DelayedColumnHeader>
      <DelayedColumnHeader style={{ width: "10%" }}>
        <DelayedRowField>Status</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="status" />
        {/* <DelayedRowField style={{marginRight: '0px'}}>Status</DelayedRowField> */}
      </DelayedColumnHeader>
      <DelayedColumnHeader>
        <DelayedRowField>Timezone</DelayedRowField>
        {/* <SortButtons sortHandler={sortResults} sortField='timezone' /> */}
      </DelayedColumnHeader>
      <DelayedColumnHeader style={{ width: "18%" }}>
        <DelayedRowField>Delay</DelayedRowField>
        <SortButtons sortHandler={sortResults} sortField="delay_time" />
      </DelayedColumnHeader>
    </Flex>
  );
};

const SortButtons = ({ sortHandler, sortField }) => {
  return (
    <Flex>
      <FontAwesomeIcon
        icon={faCaretUp}
        color="#c95cfc"
        size="1x"
        style={{ cursor: "pointer", marginRight: "4px" }}
        onClick={() => sortHandler(sortField, 1)}
      />
      <FontAwesomeIcon
        icon={faCaretDown}
        color="#c95cfc"
        size="1x"
        style={{ cursor: "pointer" }}
        onClick={() => sortHandler(sortField, -1)}
      />
    </Flex>
  );
};
