/* eslint-disable import/no-anonymous-default-export */
import { all, fork } from "redux-saga/effects";
import loginSaga from "./login.saga";
import userSaga from "./user.saga";
import themeSaga from "./theme.saga";
import myEditorContentSaga from "./myEditorContent.saga";

export default function* () {
  yield all([fork(loginSaga), fork(userSaga), fork(themeSaga), fork(myEditorContentSaga)]);
}
