import { takeLatest, all, call, put } from "redux-saga/effects";
import { get } from "lodash";
import { actions as themeActions } from "../modules/theme.module";
import { getThemeInfo } from "../../services/theme";

export function* themeRequest() {
  try {
    const apiCall = getThemeInfo;
    const response = yield call(apiCall);
    const data = get(response, "result.data", {});
    yield put(themeActions.api.theme.response(data));
  } catch (error) {
    yield put(themeActions.api.theme.error(error));
  }
}

function* themeSaga() {
  yield all([takeLatest(themeActions.api.theme.request, themeRequest)]);
}

export default themeSaga;
