/* eslint-disable react-hooks/exhaustive-deps */
import { Flex } from "@rebass/grid";
import React, { useState, useEffect, useRef } from "react";
import { Button, Input, LoaderWrapper } from "../../components";
import { connect } from "react-redux";
import { fetchThemes, uploadFile } from "../../services/add-content";
import "./style.css";
import { actions as themeActions } from "../../redux/modules/theme.module";
import Multiselect from "multiselect-react-dropdown";
import { isEmpty } from "lodash";
import ImageSelectionPopup from "./ImageSelectionPopup";
import { getThemesObjectsFromFetched } from "../../utils";
import { Link } from "react-router-dom";

const AddContentContainer = ({ userData, themeRequest, themeData }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [fileName, setFileName] = useState("");
  const [themes, setThemes] = useState([]);
  const [title, setTitle] = useState("");
  const [imagePopup, setImagePopup] = useState(false);
  const [response, setResponse] = useState();
  const [topicLoaded, setTopicLoaded] = useState(false);
  const multiselectRef = useRef(null);
  
  useEffect(() => {
    themeRequest();
  }, []);

  const handleChange = (event) => {
    setTopicLoaded(true);
    setErrorMessage("");
    setMessage("");
    // Get the selected file
    const [file] = event.target.files;
    // Get the file name and size
    const { name: fileName, size } = file;
    // Convert size in bytes to kilo bytes
    const fileSize = (size / 1000).toFixed(2);
    // Set the text content
    const fileNameAndSize = `${fileName} - ${fileSize}KB`;
    document.getElementById("file-name").textContent = fileNameAndSize;
    setFileName(event.target.files[0]);

    fetchThemes(new File([event.target.files[0]], 'uploadedfile.pdf', {
      type: 'application/pdf'
    })).then(res => {
      if(res.result.success) {
        setThemes(getThemesObjectsFromFetched(res.result.data));
        setTopicLoaded(false);
      } else {
        setThemes([]);
        setTopicLoaded(false);
      }
    }).catch(err => {
      setThemes([]);
      setTopicLoaded(false);
    })
  };

  const resetValues = () => {
    multiselectRef && multiselectRef.current && multiselectRef.current.resetSelectedValues();
  };

  const handleSubmit = () => {
    setLoading(true);
    setErrorMessage("");
    setMessage(""); 

    let themeArray =
      themes &&
      themes.map((item) => {
        return item.id;
      });

    let payload = {
      file: new File([fileName], 'uploadedfile.pdf'),
      themes: JSON.stringify({ add: themeArray, remove: [] }),
      title: title,
    };

    uploadFile(payload)
      .then((res) => {
        console.log(res);
        if (res.result.success) {
          setResponse(res.result.data);
          resetValues();
          setFileName("");
          setTitle("");
          setThemes([]);
          setLoading(false);
          setMessage("File uploaded successfully");
          document.getElementById("fileName").value = "";
          document.querySelector(".file-name").textContent = null;
          setImagePopup(true);
        } else {
          setLoading(false);
          resetValues();
          setFileName("");
          setTitle("");
          setThemes([]);
          document.getElementById("fileName").value = "";
          document.querySelector(".file-name").textContent = null;
        }
      })
      .catch((err) => {
        setLoading(false);
        resetValues();
        setFileName("");
        setTitle("");
        setThemes([]);
        document.getElementById("fileName").value = "";
        document.querySelector(".file-name").textContent = null;
        if (err.code === 2300) {
          setErrorMessage(err.message);
        }
      });
  };


  return (
    <LoaderWrapper isLoading={themeData && themeData.isFetching}>
      <Flex width="100%" flexDirection="column" pt="25px">
      <Flex width="100%" justifyContent="left" pb="15px">
                <Link to="/my-editor-content">
                    <Button
                    type="submit" 
                    >
                    BACK
                    </Button>
                </Link>
            </Flex>
        <Flex
          width="100%"
          style={{ fontWeight: "600", justifyContent: "center" }}
        >
          ADD NEW CONTENT TO MY EDITOR
        </Flex>
        <Flex width="100%" pt="25px" justifyContent="center">
          <div className="file-input">
            <input
              type="file"
              name="fileName"
              id="fileName"
              className="file"
              placeholder="Upload File"
              onChange={handleChange}
              accept=".pdf"
            />
            <label for="fileName">Choose file</label>
            <p className="file-name" id="file-name" name="file-name"></p>
          </div>
        </Flex>
        {fileName && (
          <LoaderWrapper isLoading={topicLoaded}>
          <Flex width="100%" pt="25px" justifyContent="center">
            <Multiselect
              placeholder="Select Topics"
              selectedValues={themes}
              options={
                themeData && !isEmpty(themeData.data) ? themeData.data : []
              }
              selectionLimit="4"
              ref={multiselectRef}
              displayValue="name"
              onKeyPressFn={() => { }}
              onRemove={(data) => {
                setErrorMessage("");
                setMessage("");
                setThemes(data);
              }}
              onSearch={() => { }}
              onSelect={(data) => {
                setErrorMessage("");
                setMessage("");
                setThemes(data);
              }}
              style={{
                chips: {
                  background: "#6f53a7",
                },
                multiselectContainer: {
                  color: "#6f53a7",
                },
                searchBox: {
                  border: "none",
                  "border-bottom": "1px solid #6f53a7",
                  "border-radius": "0px",
                },
              }}
            />
          </Flex>
          </LoaderWrapper>
        )}
        <Flex width="100%" mt="18px">
          <Input
            width="100%"
            containerWidth="40%"
            placeholder="Enter a Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>

        <Flex width="100%" justifyContent="center" pb="15px">
          <LoaderWrapper isLoading={loading}>
            <Button
              type="submit"
              disabled={fileName === "" || isEmpty(themes) || isEmpty(title)}
              onClick={() => {
                handleSubmit();
              }}
            >
              Submit
            </Button>
          </LoaderWrapper>
        </Flex>
        <Flex width="100%" justifyContent="center" pb="15px">
          {message}
        </Flex>
        <Flex width="100%" justifyContent="center" pb="15px">
          {errorMessage}
        </Flex>
      </Flex>
      {imagePopup && (
        <ImageSelectionPopup
          handleClose={() => {
            setImagePopup(false);
          }}
          imageUrl={
            response && response.image
              ? response.image
              : "https://apicdn.myclout.com/assets/emailer/postDefault2.jpg"
          }
          content_id={response && response.content_id}
          updateImage={uploadFile}
        />
      )}
    </LoaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  userData: state.user.data,
  themeData: state.theme,
});

const mapDispatchToProps = {
  themeRequest: themeActions.api.theme.request,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContentContainer);
