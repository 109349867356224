const colors = {
  color: {
    body: {
      background: "#f5f5f5",
    },
    font: {
      primary: "#6f53a7",
    },
    border: {
      primary: "#e1e1e1",
      secondary: "#e5e5e5",
    },
  },
};

export default colors;