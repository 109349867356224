import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const DatePickerWrapper = styled(Flex)`
  .react-datepicker__input-container {
    input {
      padding-left: 10px;
      margin-bottom: 0;
      border-radius: 8px;
      height: 35px;
      font-size: 14px;
      background-color: #e4e4e4;
      box-shadow: none;
      padding-right: 25px;
      width: 544px;
      box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
      border: 1px solid #ccc;
      color: #7b7b7b;
      -webkit-transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
      transition: border-color ease-in-out 0.15s,box-shadow ease-in-out 0.15s;
    }
  }
`;

 