import styled from "styled-components";
import { Flex } from "@rebass/grid";
import theme from "./colors";

export const ResponsiveImg = styled.img`
  height: ${({ height, small, asMioSuccessModal }) =>
    height || small ? "16px" : asMioSuccessModal ? "76px" : "100%"};
  width: ${({ width, small, asMioSuccessModal }) =>
    width || small ? "16px" : asMioSuccessModal ? "109px" : "auto"};
  margin-left: ${(props) => (props.asMioEditor ? "15px" : "0")};
`;

export const Grid = styled(Flex)`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media screen and (min-width: 768px) {
    width: 750px;
  }
  @media screen and (min-width: 992px) {
    width: 970px;
  }
  @media screen and (min-width: 1200px) {
    width: ${({ dashboard, notification }) =>
      notification ? "1300px" : dashboard ? "1125px" : "850px"};
  }
`;

export const ButtonNoBorderBackground = styled.button`
  border: 0px;
  background-color: transparent;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  color: inherit;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "")};
  font-size: ${({ fontSize }) => fontSize || ""};
`;

export const StyledHeading = styled.p`
  margin: 5px 0;
  font-size: 11px;
  font-weight: 600;
  width: 75px;
  height: 24px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  text-transform: capitalize;
`;

export const StyledInput = styled.input`
  padding-left: 10px;
  margin-bottom: 0;
  border-radius: 8px;
  height: ${({ height }) => height || "35px"};
  font-size: 14px;
  background-color: #e4e4e4;
  box-shadow: none;
  padding-right: 25px;
  width: 100%;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  border: 1px solid #ccc;
  color: #7b7b7b;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  :focus {
    border-color: #6f53a7;
  }
  :focus::placeholder {
    color: transparent;
  }
  ::placeholder {
    color: ${theme.color.font.primary};
  }
`;

export const StyledInputLarge = styled.input`
  margin-bottom: ${({ marginBottom }) => marginBottom || "15px"};
  box-shadow: none;
  background-color: ${({ bgColor }) => bgColor || "#e4e4e4"};
  border: 1px solid #a5a5a5;
  display: block;
  width: ${({ width }) => width || "100%"};
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-image: none;
  border-radius: 4px;
  :focus {
    border-color: #6f53a7;
  }
  ::placeholder {
    color: ${theme.color.font.primary};
  }
  :focus::placeholder {
    color: transparent;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const StyledButton = styled.button`
  ${({ upperCase }) => (upperCase ? "text-transform:uppercase" : "")};
  font-size: 13px;
  padding: ${({ padding }) => padding || "7px 20px;"};
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || "#eeeeee"};
  color: ${({ color }) => color || "#6f53a7"};
  border: 1px solid #a0a0a0;
  margin: ${({ margin }) => margin || "20px 0px 0px 0px"};
  cursor: pointer;
  height: ${({ height }) => height || "auto"};
  width: ${({ width }) => width || "auto"};
  ${({ disabled }) =>
    disabled &&
    "opacity: 0.5;pointer-events:none;background-color: #eeeeee;color: #bebebe;"}
  ${({ isSecondary }) =>
    isSecondary &&
    `
    background-color: #6f53a7;
    border: 1px solid #6f53a7;
    color: #fff;
  `}
`;

export const StyledUploadFileButton = styled.label`
  font-family: sans-serif;
  ${({ upperCase }) => (upperCase ? "text-transform:uppercase" : "")};
  font-size: 13px;
  padding: ${({ padding }) => padding || "7px 20px;"};
  border-radius: 8px;
  background-color: ${({ bgColor }) => bgColor || "#eeeeee"};
  color: ${({ color }) => color || "#6f53a7"};
  border: 1px solid #a0a0a0;
  margin: ${({ margin }) => margin || "20px 0px 0px 0px"};
  cursor: pointer;
  height: ${({ height }) => height || "auto"};
  width: ${({ width }) => width || "auto"};
  ${({ disabled }) => disabled && "opacity: 0.5;pointer-events:none;"}
  ${({ isSecondary }) =>
    isSecondary &&
    `
    background-color: #6f53a7;
    border: 1px solid #6f53a7;
    color: #fff;
  `}
`;

export const TwitterButton = styled(ButtonNoBorderBackground)`
  background-color: #00aced;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const LinkedInButton = styled(ButtonNoBorderBackground)`
  background-color: #0077b5;
  color: #fff;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const ErrorContainer = styled.div`
  padding: 3px;
  line-height: 16px;
  font-size: 12px;
  text-align: ${({ align }) => align || "left"};
`;

const SpriteIcons = styled.div`
  background: url(/assets/images/mio-sprite.png) no-repeat;
  display: inline-block;
`;

export const ReachIcon = styled(SpriteIcons)`
  background-position: 0px -100px;
  width: 35px;
  height: 20px;
`;

export const UpGreenIcon = styled(SpriteIcons)`
  background-position: -52px -48px;
  width: 10px;
  height: 9px;
`;

export const DownRedIcon = styled(SpriteIcons)`
  background-position: -62px -48px;
  width: 10px;
  height: 9px;
`;

export const MioSelect = styled.select`
  width: ${({ width }) => width || "180px"};
  height: ${({ height }) => height || "35px"};
  border-radius: ${({ borderRadius }) => borderRadius || "6px"};
  padding: ${({ padding }) => padding || "7px 10px"};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  margin: ${({ margin }) => margin || "0px"};
  border: 1px solid #a0a0a0;
  color: #757575;
  cursor: pointer;
  background: ${({ background }) => background || "#eee"};
  option {
    background-color: #fff;
    color: #6f53a7;
    padding: 5px 0;
    border-radius: 6px;
  }
`;

export const MioTextArea = styled.textarea`
  padding: 6px 35px;
  border-radius: 8px;
  height: auto;
  resize: none;
  margin-bottom: 15px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  background-color: #eee;
`;

export const DownloadSampleDiv = styled.a`
  text-align: left;
  padding-left: 20px;
  padding-top: 30px;
  display: block;
  text-decoration: none;
  ${({ padding }) => `padding: ${padding};`};
`;

export const CommonZoomStyles = `
  @media only screen and (min-width: 1400px) {
    zoom: 1.2;
  }
  @media only screen and (min-width: 1500px) {
    zoom: 1.3;
  }
`;

export const PremiumModalContent = styled.h6`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
`;

export const LayoutInitialWrapper = styled.div`
  background: linear-gradient(
    to bottom,
    rgba(252, 252, 252, 1) 0%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(242, 242, 242, 1) 52%,
    rgba(227, 227, 227, 1) 100%
  );
  height: 100%;
  width: 100%;
  margin-top: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
`;
