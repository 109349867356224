import React from "react";

import { Switch, Route } from "react-router-dom";
import { CreateUser, DashboardLayout, Login, AddContent, Notification, MyEditorContent, SendGridLayout } from "./layouts";

const Routes = () => (
  <div>
    <Switch>
      <Route exact path={["/", "/admin/login", "/login"]} component={Login} />
      <Route exact path="/dashboard" component={DashboardLayout} />
      <Route exact path="/create-user" component={CreateUser} />
      <Route exact path="/add-content" component={AddContent} />
      <Route exact path="/my-editor-content" component={MyEditorContent} />
      <Route exact path="/notification" component={Notification} />
      <Route exact path="/sendgrid-settings" component={SendGridLayout} />
    </Switch>
  </div>
);

export default Routes;
