import { default as userReducer } from "./modules/login.module";
import { default as usersReducer } from "./modules/user.module";
import { default as themeReducer } from "./modules/theme.module";
import { default as myEditorContentReducer } from "./modules/myEditorContent.module";

export const reducers = {
  user: userReducer,
  users: usersReducer,
  theme: themeReducer,
  myEditorContent: myEditorContentReducer
};
