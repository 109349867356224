import React from 'react';
import StepOneComponent from './StepOneComponent/StepOneComponent';
import StepTwoComponent from './StepTwoComponent/StepTwoComponent';
import StepThreeComponent from './StepThreeComponent/StepThreeComponent';
import DisconnectedComponent from './DisconnectedComponent/DisconnectedComponent';
import ConnectedComponent from './ConnectedComponent/ConnectedComponent';
import './MainContentComponent.css';

const mainContentComponent = ({ 
    stepCompleted, 
    loginDetails, 
    changeHandler, 
    loginHandler, 
    verifyHandler, 
    verifyLoader,
    nextLoader, 
    isAdmin, 
    list, 
    sgData, 
    close, 
    verificationStatus, 
    status, 
    showDisconnected,
    updateConnection,
    showDelete,
    toggleDelete,
    disconnectLoader,
    deleteLoader,
    connectLoader,
    setErrorMessage,
    updateVerificationStatus,
    toggleDeleteLoader 
}) => {
    const body = (!verificationStatus || verificationStatus === 'verification_pending') || (!isAdmin && (!sgData[0] || sgData[0].email.length === 0)) ? (
        stepCompleted === 1 ? (
            <StepOneComponent
                loginDetails={loginDetails}
                changeHandler={changeHandler}
                loginHandler={loginHandler}
                isAdmin={isAdmin}
                verificationStatus={verificationStatus}
                sgData={sgData}
                nextLoader={nextLoader}
            />
        ) : stepCompleted === 2 ? (
            <StepTwoComponent
                list={list}
                verifyHandler={verifyHandler}
                loader={verifyLoader} />
        ) : (
            <StepThreeComponent 
                close={close} 
                updateVerificationStatus={updateVerificationStatus} 
                updateConnection={updateConnection}
                toggleDelete={toggleDelete}
                deleteLoader={deleteLoader}
                showDelete={showDelete}
                setErrorMessage={setErrorMessage}
                 />
        )
    ) : (
        status === 'active' ? (
            <ConnectedComponent 
                showDisconnected={showDisconnected} 
                updateConnection={updateConnection} 
                showDelete={showDelete} 
                toggleDelete={toggleDelete}
                disconnectLoader={disconnectLoader}
                deleteLoader={deleteLoader}
                close={close}
                setErrorMessage={setErrorMessage}
            />
        ) : (
            <DisconnectedComponent 
                updateConnection={updateConnection}
                showDelete={showDelete} 
                toggleDelete={toggleDelete}
                deleteLoader={deleteLoader}
                connectLoader={connectLoader}
                setErrorMessage={setErrorMessage} />
        )
    );

    return (
        <>
            {body}
        </>
    );
}

export default mainContentComponent;