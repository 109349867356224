import apiInstance from "../api";
import { objectToFormData } from "../helpers";

export const uploadFile = (payload) => {
  const data = objectToFormData(payload);
  return apiInstance().post("/mycontent", data);
};

export const fetchThemes = (file) => {
  let payload = {
    media1: file,
    return_text: true
  } 

  const data = objectToFormData(payload);
  return apiInstance().post("/theme/fetch", data);
}
