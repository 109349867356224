import { Flex } from '@rebass/grid';
import styled from 'styled-components';


export const DelayedColumnHeader = styled(Flex)`
    width: 12%; 
    justify-content: center;
    align-items: center;
    font-size: 16px; 
    font-weight: 600;
    color: #49484a;
    cursor: pointer;
`;

export const DelayedColumn = styled.div`
    width: 12%; 
    font-size: 14px; 
    color: #49484a;
    text-align: center;
`;

export const DelayedRowField = styled.span`
    margin-right: 7px;
`;

