import React from 'react'
import { SendGridContainer } from '../../containers';
import withDashboardLayout from '../../hoc/withDashboardLayout';

const SendgridLayout = () => {
  return (
    <SendGridContainer />
  )
}

export default withDashboardLayout(SendgridLayout);