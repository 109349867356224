import { takeLatest, all, call, put } from 'redux-saga/effects';
import { getAllUsers } from '../../services/users';
import { get } from 'lodash';
import { actions as userActions } from '../modules/user.module'; 

export function* userRequest() {
  try {
    console.log('check');
    const apiCall = getAllUsers;

    const response = yield call(apiCall);
    const data = get(response, 'result.data', {});
    yield put(userActions.api.user.response(data));
  } catch (error) {
    yield put(userActions.api.user.error(error));
  }
}

function* userSaga() {
  yield all([takeLatest(userActions.api.user.request, userRequest)]);
}

export default userSaga;