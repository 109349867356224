import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';

import { Input, ButtonNoBorderBackground } from '../';
const PasswordWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  .btn-lock {
    position: absolute;
    top: 10px;
    right: 5px;
  }
`;

const Password = ({
  name,
  id,
  value,
  placeholder,
  error,
  onChange,
  largeInput,
}) => {
  const [inputType, setInputType] = useState('password');

  const lockIcon = inputType === 'password' ? faLock : faUnlock;

  const onLockIconClick = () => {
    const newInputType = inputType === 'password' ? 'text' : 'password';
    setInputType(newInputType);
  };
  return (
    <PasswordWrapper>
      <Input
        id={id}
        name={name}
        type={inputType}
        placeholder={placeholder}
        onChange={onChange}
        error={error}
        value={value}
        largeInput={largeInput}
      />
      <ButtonNoBorderBackground className="btn-lock" onClick={onLockIconClick}>
        <FontAwesomeIcon icon={lockIcon} />
      </ButtonNoBorderBackground>
    </PasswordWrapper>
  );
};

export default Password;
