import React from 'react'
import { CreateUserContainer } from '../../containers';
import withDashboardLayout from '../../hoc/withDashboardLayout';


const CreateUser = (props) => {
  return (
    <CreateUserContainer {...props} />
  )
}

export default withDashboardLayout(CreateUser);
