import { handleActions } from 'redux-actions';
import { apiModuleDefaultState, createApiActions } from './helpers';

export const actions = createApiActions('user');


const reducer = handleActions(
  {
    [actions.api.user.request]: (_, { payload }) => ({
      isFetching: true,
      isError: false,
    }),
    [actions.api.user.response]: (_, { payload }) => ({
      isFetching: false,
      isError: false,
      data: payload,
    }),
    [actions.api.user.error]: (_, { payload }) => ({
      isFetching: false,
      isError: true,
      error: payload,
    }),
    [actions.api.user.filter]: (state, { payload }) => {
      const filterUsers = state.data.filter(u => (u.email.includes(payload.searchText)) || (u.first_name.includes(payload.searchText)) || (u.last_name.includes(payload.searchText)));
      return {
        ...state,
        filterData: filterUsers
      }
    }
  },
  { ...apiModuleDefaultState, data: {}, error: null }
);

export default reducer;