/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Flex } from "@rebass/grid";
import {
  DashboardWrapper,
  StyledSearchInput,
  SearchButton,
  ClearButton,
} from "./DashboardContainer.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Table,
  EmailListTableHeading,
  Tr,
  EmailListTh,
  TableBody,
  EmailListTableBodyTd,
  EmailListTableBodyTr,
  Button,
  LoaderWrapper,
} from "../../components";
import { actions as userActions } from "../../redux/modules/user.module";
import { connect } from "react-redux";
import { getUserToken } from "../../services/users";
import { urlDetails } from "../../services/helpers";
import { get } from "lodash";

const AdminDashboardContainer = ({
  users,
  getAllUsersRequest,
  usersFetching,
  filterUsersRequest,
  filteredUsers,
}) => {
  useEffect(() => {
    getAllUsersRequest();
  }, []);

  // console.log(users);
  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setSearchText(e.target.value);
    filterUsersRequest({ searchText: e.target.value });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    // console.log("check");
    filterUsersRequest({ searchText: searchText });
  };

  const handleClearText = () => {
    setSearchText("");
    filterUsersRequest({ searchText: "" });
  };

  const goToApp = (e, item) => {
    const payload = {};
    const hostUrl = window.location.host;
    const subDomain = hostUrl.split(".");
    let userToken = "",
      superAdminEmail = "";
    payload["user_email"] = item.email;
    let adminUrl =
      item.role_type === "general" ? "/home" : "/admin/home";
    let baseUrl =
      subDomain[0] === "admin" ? urlDetails.prodUrl : urlDetails.devUrl;
    getUserToken(payload)
      .then((res) => {
        if (res.result.success) {
          userToken = res.result.data.token;
          superAdminEmail = get(
            res.result.data.details.user,
            "superAdminEmail",
            ""
          );
          if (item.role_type === "general") {
            window.open(
              `${baseUrl}${adminUrl}?super_admin_email=${superAdminEmail}&userToken=${userToken}/`,
              "_blank" // <- This is what makes it open in a new window.
            );
          } else {
            const generalToken = res.result.data.secondary_account.data.token;
            window.open(
              `${baseUrl}${adminUrl}?super_admin_email=${superAdminEmail}&userToken=${userToken}&generalToken=${generalToken}/`,
              "_blank" // <- This is what makes it open in a new window.
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <DashboardWrapper>
      <Flex width={1} justifyContent="center">
        <div style={{ position: "relative" }}>
          <StyledSearchInput
            autoComplete="off"
            type="text"
            name="searchText"
            placeholder="Search"
            value={searchText}
            onChange={(e) => {
              handleChange(e);
            }}
            onKeyDown={handleKeyDown}
          />
          <SearchButton type="submit" onClick={handleSubmit}>
            <FontAwesomeIcon icon={faSearch} />
          </SearchButton>
          {searchText && searchText.length > 0 && (
            <ClearButton type="submit" onClick={handleClearText}>
              <FontAwesomeIcon icon={faTimes} />
            </ClearButton>
          )}
        </div>
      </Flex>
      <Flex width={1} pt="20px">
        <Table height="auto">
          <LoaderWrapper loaderHeight="100px" isLoading={usersFetching}>
            <EmailListTableHeading>
              <Tr>
                <EmailListTh align="left">Email</EmailListTh>
                <EmailListTh align="left" width="20%">
                  First Name
                </EmailListTh>
                <EmailListTh align="left" width="20%">
                  Last Name
                </EmailListTh>
                <EmailListTh align="left" width="20%">
                  Actions
                </EmailListTh>
              </Tr>
            </EmailListTableHeading>
            <TableBody height="auto">
              {filteredUsers && filteredUsers.length > 0
                ? filteredUsers.map((item, index) => (
                    <EmailListTableBodyTr
                      alternate={false}
                      key={item.email + index}
                    >
                      <EmailListTableBodyTd align="left">
                        {item.email}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        {item.first_name}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        {item.last_name}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        <Button margin="0px" onClick={(e) => goToApp(e, item)}>
                          Redirect
                        </Button>
                      </EmailListTableBodyTd>
                    </EmailListTableBodyTr>
                  ))
                : users &&
                  users.length > 0 &&
                  users.map((item, index) => (
                    <EmailListTableBodyTr
                      alternate={false}
                      key={item.email + index}
                    >
                      <EmailListTableBodyTd align="left">
                        {item.email}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        {item.first_name}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        {item.last_name}
                      </EmailListTableBodyTd>
                      <EmailListTableBodyTd align="left" width="20%">
                        <Button margin="0px" onClick={(e) => goToApp(e, item)}>
                          Redirect
                        </Button>
                      </EmailListTableBodyTd>
                    </EmailListTableBodyTr>
                  ))}
            </TableBody>
          </LoaderWrapper>
        </Table>
      </Flex>
    </DashboardWrapper>
  );
};

const mapStateToProps = (state) => ({
  users: state.users.data,
  usersFetching: state.users.isFetching,
  filteredUsers: state.users.filterData || [],
});

const mapDispatchToProps = {
  getAllUsersRequest: userActions.api.user.request,
  filterUsersRequest: userActions.api.user.filter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardContainer);
