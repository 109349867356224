import styled from 'styled-components';
import { TableHeading, Th, Tr, Td } from '..';

export const EmailListTableHeading = styled(TableHeading)`
  background-color: #d5d5d5;
  border: 0px;
`;

export const EmailListTh = styled(Th)`
  padding: 8px 8px;
  color: #7b7b7b;
  line-height: 1.42857143;
  font-weight: bold;
  position: relative;
`;

export const EmailListTableBodyTd = styled(Td)`
  background-color: #ecebeb;
  padding: 8px 10px;
  color: #7b7b7b;
`;

export const EmailListTableBodyTr = styled(Tr)`
  border-bottom: 4px solid #d5d5d5;
`;