import { get, isEmpty, keys } from "lodash";

export const redirectToLogin = () => {
  window.location = "/login";
  // window.location.href = process.env.REACT_APP_REDIRECT_URL;
  sessionStorage.removeItem("token");
};

export const clearSeassionStorage = () => {
  sessionStorage.clear();
  window.location = "/login";
};

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const encryptPassword = (password, CryptoJS) => {
  const salt = CryptoJS.enc.Hex.parse("a8f556b3cb926c09c33aa9257a531590");
  const iv = CryptoJS.enc.Hex.parse("d45bedbbec2a1f26aabcd2cbc97b048d");

  let encrypted = CryptoJS.AES.encrypt(password, salt, {iv:iv, padding: CryptoJS.pad.ZeroPadding});
  encrypted = encrypted.ciphertext.toString(CryptoJS.enc.Base64);

  return encrypted;
}

export const getThemesObjectsFromFetched = (response) => {
  const selectedThemeList = [];
  const themeVertical = get(response, 'themes.vertical');
  keys(themeVertical).forEach((k1) => {
    const k1Object = themeVertical[k1];
    keys(k1Object).forEach((k2) => {
      if (get(k1Object, `${k2}.frequency`) >= 3) {
        selectedThemeList.push({ ...k1Object[k2], name: k2 });
      }
    });
  });
  if(!isEmpty(selectedThemeList)) {
    return selectedThemeList.sort((a, b) => b.frequency - a.frequency).slice(0, 4); 
  } else {
    return [];
  }
 
};

export const copyToClipboard = (str) => {
  let el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};
