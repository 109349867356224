import React from 'react';

import { StyledButton, ButtonNoBorderBackground } from '../CommonStyles';

export const Button = ({
  type,
  upperCase,
  onClick,
  children,
  disabled,
  className,
  height,
  width,
  margin,
  padding,
  isSecondary,
  color,
  bgColor,
  styles,
}) => (
  <StyledButton
    type={type || 'button'}
    upperCase={upperCase}
    onClick={onClick}
    disabled={disabled}
    className={className}
    height={height}
    width={width}
    margin={margin}
    padding={padding}
    isSecondary={isSecondary}
    color={color}
    bgColor={bgColor}
    style={styles ? styles : {}}
  >
    {children}
  </StyledButton>
);

export const DripIconButton = ({ onClick, icon, className, title, styles, disable }) => (
  <ButtonNoBorderBackground
    type="button"
    onClick={onClick}
    data-btn-type={`icon-${icon}-btn`}
    className={className}
    disabled={disable || false}
  >
    <div className={`icon dripicons-${icon}`} title={title} style={styles}></div>
  </ButtonNoBorderBackground>
);
