/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, LoaderWrapper } from '../../../../../components';

const disConnectedComponent = ({ updateConnection, showDelete, toggleDelete, deleteLoader, connectLoader, setErrorMessage }) => {
    return (
        <>
            {
                showDelete ? (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={deleteLoader}>
                            <Button onClick={() => updateConnection('delete')}>Delete the connection</Button>
                        </LoaderWrapper>
                        <br />
                        <a href='#' className='connected-delete-btn' onClick={() => {
                            setErrorMessage('');
                            toggleDelete(false);
                        }}>Back</a>
                    </div>
                ) : (
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <LoaderWrapper isLoading={connectLoader}>
                            <Button onClick={() => updateConnection('connect')} >Connect</Button>
                        </LoaderWrapper>
                        <p style={{ color: '#555', margin: '6px auto' }}>or</p>
                        <Button margin='0 0 10px 0' onClick={() => toggleDelete(true)} >Delete the connection</Button>
                    </div>
                )
            }
        </>
    );
}

export default disConnectedComponent;