import { padStart } from 'lodash';
import sha256 from 'js-sha256';
import apiInstance from '../api';
import { objectToFormData } from '../helpers';

export const getAuthToken = (payload, social) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = padStart(date.getMonth() + 1, 2, '0');
  const day = padStart(date.getDate(), 2, '0');
  const preEncryptionString = social
    ? `${year}/${month}/${day}`
    : `${payload.email}-${year}/${month}/${day}`;
  const authToken = sha256(preEncryptionString);

  return authToken;
};

export const authenticate = (payload) => {
  const authToken = getAuthToken(payload);

  // payload.password = encryptPassword(payload.password, CryptoJS);
  payload.encrypted = 'false';

  return apiInstance({ isReactLogin: true }).post(
    '/user/login',
    objectToFormData(payload),
    {
      headers: { Authorization: `Bearer ${authToken}` },
    }
  );
};

export const getUserDetails = () => {
  return apiInstance().get('/user');
};
