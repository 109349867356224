import { Flex } from '@rebass/grid';
import { cloneDeep, isEmpty } from 'lodash-es';
import React, { useEffect, useState } from 'react';
import { DelayedCampaignRow, DelayedCampaignRowHeader } from '../../components/DelayedCampaignRow/DelayedCampaignRow';
import { getDelayedPosts } from '../../services/notification';
import { LoaderWrapper } from '../../components/Loader';
import { Button } from '../../components';

const NotificationContainer = () => {

    const [delayedCampaigns, setDelayedCampaigns] = useState([]);
    const [loader, toggleLoader] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalDelayedItems, setTotalDelayedItems] = useState(0);

    useEffect(() => {
        toggleLoader(true);
        const offset = currentPage*20;
        getDelayedPosts(offset)
        .then(response => {
            const formattedResponse = response.result.data.map(ele => {
                const totalPosts = ele.email_count + ele.facebook_count + ele.linkedin_count + ele.twitter_count
                return {
                    ...ele,
                    delay_time: ele.elapsed_time,
                    total_count: totalPosts
                }
            });
            setDelayedCampaigns(formattedResponse);
            setTotalDelayedItems(parseInt(response.result.total_count));
            toggleLoader(false);
        })
        .catch(err => {
            console.log('ERROR - - - -', err);
            toggleLoader(false);
        })
    }, [currentPage]);


    const sortResultsByParam = (sortField, order) => {
        let delayedCampaignsCopy = cloneDeep(delayedCampaigns);
        if(sortField === 'scheduled_time') {
            if(order === 1)
                delayedCampaignsCopy.sort((a, b) => (new Date(a[sortField]) < new Date(b[sortField])) ? -1 : (new Date(a[sortField]) > new Date(b[sortField])) ? 1 : 0);
            else
                delayedCampaignsCopy.sort((a, b) => (new Date(b[sortField]) < new Date(a[sortField])) ? -1 : (new Date(b[sortField]) > new Date(a[sortField])) ? 1 : 0);
        }
        else if(sortField === 'delay_time') {
            if(order === 1) 
                delayedCampaignsCopy.sort((a, b) => {
                    const delayArray1 = a[sortField].split(':');
                    const delayArray2 = b[sortField].split(':');
                    const totalMins1 = parseInt(delayArray1[0].split('DAYS')[0].trim())*24*60
                        + parseInt(delayArray1[1].split('HRS')[0].trim())*60
                        + parseInt(delayArray1[2].split('MINS')[0].trim());
                    const totalMins2 = parseInt(delayArray2[0].split('DAYS')[0].trim())*24*60
                        + parseInt(delayArray2[1].split('HRS')[0].trim())*60
                        + parseInt(delayArray2[2].split('MINS')[0].trim());
                    // console.log('CONDITION RESULT - - - - ', totalMins2 < totalMins1 ? -1 : totalMins2 > totalMins1 ? 1 : 0);
                    return totalMins1 < totalMins2 ? -1 : totalMins1 > totalMins2 ? 1 : 0
                });
            else 
                delayedCampaignsCopy.sort((a, b) => {
                    const delayArray1 = a[sortField].split(':');
                    const delayArray2 = b[sortField].split(':');
                    const totalMins1 = parseInt(delayArray1[0].split('DAYS')[0].trim())*24*60
                        + parseInt(delayArray1[1].split('HRS')[0].trim())*60
                        + parseInt(delayArray1[2].split('MINS')[0].trim());
                    const totalMins2 = parseInt(delayArray2[0].split('DAYS')[0].trim())*24*60
                        + parseInt(delayArray2[1].split('HRS')[0].trim())*60
                        + parseInt(delayArray2[2].split('MINS')[0].trim());// console.log('CONDITION RESULT (reverse) - - - - ', totalMins2 < totalMins1 ? -1 : totalMins2 > totalMins1 ? 1 : 0);
                    return totalMins2 < totalMins1 ? -1 : totalMins2 > totalMins1 ? 1 : 0
                });
        }
        else {
            if(order === 1)
                delayedCampaignsCopy.sort((a, b) => ((a[sortField] < b[sortField]) ? -1 : (a[sortField] > b[sortField]) ? 1 : 0));
            else
                delayedCampaignsCopy.sort((a, b) => ((b[sortField] < a[sortField]) ? -1 : (b[sortField] > a[sortField]) ? 1 : 0));
        }
        setDelayedCampaigns(delayedCampaignsCopy);
    }

    // const handlePagination = mode => {
    //     let pageOffset = currentPage;
    //     if(mode === 'next')
    //         pageOffset++;
    //     else
    //         pageOffset--;
    //     fetchDelayedPosts(pageOffset*20);
    // }

    return (
            <Flex flexDirection='column' width='100vw'>
                <Flex width='100%' justifyContent='flex-end' alignItems='baseline'>
                    {delayedCampaigns.length > 0 && (
                        <span style={{fontFamily: 'sans-serif', color: '#49484a', fontSize: '14px'}}>
                            {currentPage*20 + 1} - {(currentPage*20 + 20) < totalDelayedItems ? (currentPage*20 + 20) : totalDelayedItems} of {totalDelayedItems}
                        </span>
                    )}
                    <Flex width='46%' justifyContent='flex-end' alignItems='center'>
                        <Button
                            styles={{ marginLeft: '10px', borderRadius: '3px' }}
                            onClick={() => setCurrentPage(currentPage-1)}
                            disabled={totalDelayedItems === 0 || loader || currentPage === 0}
                        >
                            Previous
                        </Button>
                        <Button 
                            styles={{ marginLeft: '10px', borderRadius: '3px' }}
                            onClick={() => setCurrentPage(currentPage+1)}
                            disabled={totalDelayedItems === 0 || loader || (currentPage+1)*20 >= totalDelayedItems }
                        >
                            Next
                        </Button>
                    </Flex>
                </Flex>
                <LoaderWrapper isLoading={loader} styles={{height: '100vh'}}>
                    {!isEmpty(delayedCampaigns) ? (
                    <Flex 
                        flexDirection='column' 
                        justifyContent='flex-start' 
                        alignItems='center' 
                        marginTop='1%'
                        width='100%'
                        style={{
                            fontFamily: 'sans-serif', 
                            border: '1px solid #49484a',
                            borderRadius: '8px',
                            maxHeight: '80vh',
                            overflowY: 'auto'
                        }}
                    >
                        <DelayedCampaignRowHeader sortResults={sortResultsByParam} />
                        {delayedCampaigns.map((campaign, index) => (
                            <DelayedCampaignRow 
                                campaign={campaign} 
                                index={index} 
                            />
                        ))}
                    </Flex>
                    ) : (
                        <div>No delayed mails found</div>
                    )}
                </LoaderWrapper>
            </Flex>
    );
};

export default NotificationContainer;